import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink
} from '@chakra-ui/react';
import React from 'react';
import { BiHome } from 'react-icons/bi';
import './Breadcrumbs.css';

function Breadcrumbs({caminho, ...rest}) {

  return (
    <Breadcrumb spacing="8px" {...rest}>
      <BreadcrumbItem>
        <BreadcrumbLink href="/Dashboard" fontSize={18}>
          <BiHome />
        </BreadcrumbLink>
      </BreadcrumbItem>
      {caminho.map((item, index) => {
        if (item.path) {
          return (
            <BreadcrumbItem key={index}>
              <BreadcrumbLink href={item.path} fontSize={18}>
                {item.nome}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        }
        return (
          <BreadcrumbItem key={index} isCurrentPage fontSize={18} className='isCurrentItem'>
            <BreadcrumbLink isCurrentPage className='isCurrentPage'>{item.nome}</BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
