import {
  Box,
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import Botao from '../../components/Botao/Botao';
import IconeFiltro from '../../components/Icones/IconeFiltro/IconeFiltro';
import InputCheckbox from '../../components/Inputs/InputCheckbox/InputCheckbox';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSearch from '../../components/Inputs/InputSearch/InputSearch';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { filtrar } from '../../utils/filtros';
import { formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import {
  GetDatasAjustadas,
  ajustaDatas,
  optionsDatas,
  sortAZ,
} from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';

function RelSinteseFinanceira() {
  const optionsData = [
    'Filtrar por Data de Competência',
    'Filtrar por Data de Vencimento',
  ];
  const optionsTipo = ['Previsto', 'Realizado'];
  const [
    showMenuFiltersRelSinteseFinanceira,
    setShowMenuFiltersRelSinteseFinanceira,
  ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relSinteseFinanceira, setRelSinteseFinanceira] = useState([]);
  const [totalReceitas, setTotalReceitas] = useState(0);
  const [totalDespesas, setTotalDespesas] = useState(0);
  const [opcaoData, setOpcaoData] = useState(optionsDatas[0]);
  const [desc, setDesc] = useState(false);

  const handleChangeOpcaoData = e => {
    setOpcaoData(e.target.value);
    var dataFiltro = GetDatasAjustadas(e.target.value);
    formik.setFieldValue('DataInicial', dataFiltro.dataInicial);
    formik.setFieldValue('DataFinal', dataFiltro.dataFinal);
  };

  async function GetRelSinteseFinanceira(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/SinteseFinanceiraReport/GetList', payload);
    let data = res.data;

    setRelSinteseFinanceira(data.Lancamentos);
  }

  var date = new Date();
  date.setDate(date.getDate() - 100);

  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      Empresa: '',
      TipoData: 'emissao',
      DataInicial: date,
      DataFinal: new Date(),
    },
    onSubmit: async values => {
      await GetRelSinteseFinanceira(values);
    },
  });

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 100);

    const filtro_padrao = {
      Empresa: '',
      TipoData: 'emissao',
      DataInicial: date,
      DataFinal: new Date(),
    };
    setShowMenuFiltersRelSinteseFinanceira(search.get('filter') === 'true');
    GetRelSinteseFinanceira(filtro_padrao);
  }, [search]);

  useEffect(() => {
    if (relSinteseFinanceira && Object.keys(relSinteseFinanceira).length > 0) {
      let receitas = 0;
      let despesas = 0;
      relSinteseFinanceira.map(rel =>
        rel.Tipo === 'Receita'
          ? (receitas += rel.ValorTotal)
          : (despesas += rel.ValorTotal)
      );
      setTotalReceitas(receitas);
      setTotalDespesas(despesas);
      setLoading(false);
    }
  }, [relSinteseFinanceira]);

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relSinteseFinanceira, null, 2),
    };
    baixarArquivoBase64('/SinteseFinanceiraReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relSinteseFinanceira, null, 2),
    };
    baixarArquivoBase64('/SinteseFinanceiraReport/GetExcel', payload);
  };

  function handleCheck(item, value) {
    let itens = relSinteseFinanceira;
    for (let i = 0; i < itens.length; i++) {
      if (itens[i].Id === item.Id) {
        itens[i].Selecionado = value;
        break;
      }
    }
    setRelSinteseFinanceira([...itens]);
  }

  function handleDoubleClick(param) {
    setDesc(!desc);
    setRelSinteseFinanceira(sortAZ(relSinteseFinanceira, desc, param));
  }

  return (
    <>
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc6}>
                <InputSearch
                  placeholder="Procurar por relatórios"
                  value={searchBar}
                  onChange={e => setSearchBar(e.target.value)}
                />
              </Box>
              <Box p={4} gridColumn={gridColumn.gc6}>
                <div className="searchbuttons">
                  <Botao variant="verde" onClick={getExcel}>
                    Exportar Planilha
                  </Botao>
                  <Botao variant="rosa" onClick={getPDF}>
                    Exportar PDF
                  </Botao>
                </div>
              </Box>
              <Box gridColumn={gridColumn.gc6}>
                <button
                  type="button"
                  onClick={() =>
                    setShowMenuFiltersRelSinteseFinanceira(
                      !showMenuFiltersRelSinteseFinanceira
                    )
                  }
                >
                  <IconeFiltro />
                </button>
              </Box>
              {showMenuFiltersRelSinteseFinanceira ? (
                <Box mb={5} gridColumn={gridColumn.gc12}>
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    mx={1}
                    width="100%"
                    height="100%"
                    maxBlockSize={'300px'}
                    className="filtersBox"
                  >
                    <Box gridColumn={gridColumn.gc4}>
                      <div className="search">
                        <p className="pSearchRelSinteseFinanceira">EMPRESA</p>
                        <InputTexto />
                      </div>
                    </Box>
                    <Box gridColumn={gridColumn.gc4}>
                      <div className="search">
                        <p className="pSearchRelSinteseFinanceira">TIPO</p>
                        <InputSelect options={optionsTipo} />
                      </div>
                    </Box>
                    <Box gridColumn={gridColumn.gc4}>
                      <div className="search">
                        <p className="pSearchRelSinteseFinanceira">
                          FILTRAR POR DATA
                        </p>
                        <InputSelect options={optionsData} />
                      </div>
                    </Box>
                    <Box gridColumn={gridColumn.gc3}>
                      <div className="search">
                        <p className="pSearchRelSinteseFinanceira">DATAS</p>
                        <InputSelect
                          options={optionsDatas}
                          onChange={handleChangeOpcaoData}
                          value={opcaoData}
                        />
                      </div>
                    </Box>
                    <Box gridColumn={gridColumn.gc3}>
                      <div className="search">
                        <p className="pSearchRelSinteseFinanceira">
                          DATA INICIAL
                        </p>
                        <InputData />
                      </div>
                    </Box>
                    <Box gridColumn={gridColumn.gc3}>
                      <div className="search">
                        <p className="pSearchRelSinteseFinanceira">
                          DATA FINAL
                        </p>
                        <InputData />
                      </div>
                    </Box>
                    <Box gridColumn={gridColumn.gc3}>
                      <Botao mt={5} mr={2} variant="azulEscuro" type="submit">
                        FILTRAR
                      </Botao>
                      <Botao
                        mt={5}
                        variant="amarelo"
                        onClick={() => {
                          var url = window.location.href + '?filter=true';
                          window.location.href = url;
                        }}
                      >
                        LIMPAR FILTROS
                      </Botao>
                    </Box>
                  </Grid>
                </Box>
              ) : (
                ''
              )}
              <Box mb={10} gridColumn={gridColumn.gc12}>
                <Grid
                  gridTemplateColumns="repeat(12, 1fr)"
                  mx={1}
                  width="100%"
                  height="100%"
                  maxBlockSize={'300px'}
                  className="itensRelatorio"
                >
                  <Box gridColumn={gridColumn.gc6}>
                    <p>TOTAL DE RECEITAS</p>
                    <b>{formataDinheiro(totalReceitas)}</b>
                  </Box>
                  <Box gridColumn={gridColumn.gc6}>
                    <p>TOTAL DE DESPESAS</p>
                    <b>{formataDinheiro(totalDespesas)}</b>
                  </Box>
                </Grid>
              </Box>
              <Box mb={7} className="card" gridColumn={gridColumn.gc12}>
                <SecaoCadastro titulo="RELATÓRIO DE SÍNTESE FINANCEIRA" />
                <Box
                  className="RelSinteseFinanceiraCadastrados"
                  gridColumn={gridColumn.gc12}
                >
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>
                            <button
                              onDoubleClick={() =>
                                handleDoubleClick('PlanoConta')
                              }
                              type="button"
                            >
                              PLANO DE CONTAS
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() =>
                                handleDoubleClick('ValorTotal')
                              }
                              type="button"
                            >
                              VALOR
                            </button>
                          </Th>
                          <Th>
                            <button
                              onDoubleClick={() => handleDoubleClick('')}
                              type="button"
                            >
                              PERCENTUAL
                            </button>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {relSinteseFinanceira
                          .filter(item => filtrar(item, searchBar))
                          .map(relatorio => (
                            <Tr
                              key={relatorio.Id}
                              className={`${
                                relatorio.Selecionado ? 'checked' : 'notChecked'
                              }`}
                            >
                              <Td>
                                <InputCheckbox
                                  item={relatorio}
                                  initialValue={relatorio.Selecionado}
                                  handleChange={handleCheck}
                                />
                              </Td>
                              <Td>{relatorio.PlanoConta}</Td>
                              <Td>{formataDinheiro(relatorio.ValorTotal)}</Td>
                              <Td>{} %</Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  const optionsData = [
    { value: 'vencimento', name: 'Filtrar por Data de Vencimento' },
    { value: 'competencia', name: 'Filtrar Por Data de Competência' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />

      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelSinteseFinanceira;
