import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import { getFiltroPadraoPedidos } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import './Pedidos.css';

function ListarPedidos() {
  const navigate = useNavigate();
  const optionsData = [
    { value: 'cadastro', name: 'Data de Cadastro' },
    { value: 'validade', name: 'Data de Validade' },
    { value: 'faturamento', name: 'Data de Faturamento' },
  ];
  const optionsStatus = [
    { value: '', name: 'Selecione' },
    { value: 'Orcamento', name: 'Orçamento' },
    { value: 'Pedido', name: 'Pedido' },
    { value: 'PedidoFaturado', name: 'Pedido Faturado' },
    { value: 'PedidoCancelado', name: 'Pedido Cancelado' },
  ];
  const [showFilters, setShowFilters] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  async function GetPedidos(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/ListPedidos/Get', payload);
    if (res.status === 200) {
      let data = res.data;
      data.Pedidos.forEach(b => {
        b.Selecionado = false;
      });
      setPedidos(data.Pedidos);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }
  useEffect(() => {
    setShowFilters(search.get('filter') === 'true');
    GetPedidos(null);
  }, [search]);

  function handleEdit(item) {
    navigate(`/Pedidos/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Pedidos/Editar');
  }

  function handleDelete(item) {
    setSelectedItem(item.Id);
    setAlertTitle('Excluir');
    setMessage('Deseja excluir o pedido?');
    onOpenExcluir();
  }

  let deleteItem = async () => {
    let payload = {
      data: selectedItem,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListPedidos/Delete', payload);
    let data = res.data;
    onCloseExcluir();
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setPedidos(pedidos.filter(item => item.Id !== selectedItem));
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  function ParseStatusPedido(codigo) {
    return optionsStatus.find(item => item.value === codigo).name;
  }

  return (
    <>
      <AlertaConfirmacao
        isOpen={isOpenExcluir}
        onClose={onCloseExcluir}
        description={message}
        title={alertTitle}
        onConfirm={() => deleteItem()}
      />
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          initialValues={getFiltroPadraoPedidos()}
          onSubmit={async values => {
            await GetPedidos(values);
          }}
        >
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por pedidos',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Filtros show={showFilters}>
            <InputFiltro
              titulo="EMPRESA"
              id="Empresa"
              name="Empresa"
              component={InputAutoComplete}
              autocomplete={true}
              path={'Empresa'}
              database={localStorage.getItem('database')}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="Cliente"
              id="Cliente"
              name="Cliente"
              component={InputAutoComplete}
              autocomplete={true}
              path={'ClienteFornecedor'}
              database={localStorage.getItem('database')}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="VENDEDOR"
              id="Vendedor"
              name="Vendedor"
              component={InputAutoComplete}
              autocomplete={true}
              path={'Vendedor'}
              database={localStorage.getItem('database')}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="CÓDIGO"
              id="Codigo"
              name="Codigo"
              component={InputTexto}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="FILTRAR POR"
              id="TipoData"
              name="TipoData"
              component={InputSelect}
              options={optionsData}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="Data Inicial"
              id="DataInicial"
              name="DataInicial"
              component={InputData}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="Data Final"
              id="DataFinal"
              name="DataFinal"
              component={InputData}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="Status"
              id="Status"
              name="Status"
              component={InputSelect}
              options={optionsStatus}
              gridColumn={gridColumn.gc4}
            />
          </Filtros>
          <Tabela
            titulo={'LISTAGEM DE PEDIDOS'}
            setFunction={setPedidos}
            data={pedidos}
            columns={[
              {
                placeholder: 'CÓDIGO',
                name: 'Codigo',
                type: 'number',
              },
              {
                placeholder: 'CLIENTE',
                name: 'Cliente',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
              {
                placeholder: 'STATUS',
                name: 'Status',
                type: 'function',
                function: ParseStatusPedido,
              },
              {
                placeholder: 'SITUAÇÃO',
                name: 'SituacaoSTR',
                type: 'text',
              },
              {
                placeholder: 'CADASTRO',
                name: 'DataCadastro',
                type: 'date',
              },
              {
                placeholder: 'FORMA DE PAGAMENTO',
                name: 'FormaPagamentoSTR',
                type: 'text',
              },
              {
                placeholder: 'VALOR',
                name: 'ValorTotal',
                type: 'money',
              },
              {
                placeholder: 'NF-E',
                name: 'NumeroNFe',
                type: 'text',
              },
              {
                placeholder: 'ORIGEM',
                name: 'Origem',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            useCheck={false}
            handleDelete={handleDelete}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

export default ListarPedidos;
