const valor_inicial = {
  Nome: '',
  Empresa: '',
  EmpresaId: '',
  CEP: '',
  RuaEndereco: '',
  NumeroEndereco: '',
  ComplementoEndereco: '',
  BairroEndereco: '',
  CidadeEndereco: '',
  CodigoCidadeEndereco: '',
  UFEndereco: '',
  CodigoUFEndereco: '',
  PaisEndereco: '',
  CodigoPaisEndereco: '',
  Responsavel: '',
  Telefone: '',
  Email: '',
  Id: '',
};

export { valor_inicial };

