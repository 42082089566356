import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import Botao from '../../components/Botao/Botao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL, estados } from '../../utils/utils';
import './OperacoesFiscais.css';

function EditarOperacoesFiscais() {
  const navigate = useNavigate();
  const optionsPIS = [
    '01-Operação Tributável com Alíquota Básica',
    '02-Operação Tributável com Alíquota Diferenciada',
    '03-Operação Tributável com Alíquota por unidade de Medida de Produto',
    '04-Operação Tributável Monofásica - Revenda a Alíquota Zero',
    '05-Operação Tributável Alíquota Zero',
    '08-Operação Insenta da Contribuição',
    '09-Operação com Suspensão da Contribuição',
    '49-Outras Operações de Saída',
    '51-Operação com Direito a Crédito-Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
    '52-Operação com Direito a Crédito-Vinculada Exclusivamente a Receita de Exportação',
    '53-Operação com Direito a Crédito-Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
    '54-Operação com Direito a Crédito-Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
    '55-Operação com Direito a Crédito-Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
    '56-Operação com Direito a Crédito-Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação',
    '99-Outras Operações',
  ];
  const optionsCOFINS = [
    '01-Operação Tributável com Alíquota Básica',
    '02-Operação Tributável com Alíquota Diferenciada',
    '03-Operação Tributável com Alíquota por Unidade de Medida de Produto',
    '04-Operação Tributável Monofásica-Revenda a Alíquota Zero',
    '06-Operação Tributável a Alíquota Zero',
    '07-Operação Insenta da Contribuição',
    '08-Operação sem Incidência da Contribuição',
    '09-Operação com Suspensão da Contribuição',
    '49-Outras Operações de Saída',
    '51-Operação com Direito a Crédito-Vinculada Exlusivamente a Receita Tributada no Mercado Interno',
    '52-Operação com Direito a Crédito-Vinculada Exclusivamente a Receita de Exportação',
    '53-Operação com Direito a Crédito-Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
    '54-Operação com Direito a Crédito-Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
    '55-Operação com Direito a Crédito-Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
    '56-Operação com Direito a Crédito-Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação',
    '99-Outras Operações',
  ];
  const optionsICMS = [
    '00-Tributada Integralmente',
    '10-Tributada e com cobrança do ICMS por substituição tributária',
    '20-Com redução de base de cálculo',
    '30-Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
    '40-isenta',
    '50-Suspensão',
    '51-Diferimento',
    '60-ICMS cobrado anteriormente por substituição tributária',
    '70-Com redução de base de cálculo e cobrança do ICMS por substituição tributária',
    '90-Outros',
    '101-Tribuada com permissão de crédito (referente ao simples nacional)',
    '102-Tributada sem permissão de crédito (referente ao simples nacional)',
    '103-Isenção do ICMS para a faixa de receita bruta (referente ao simples nacional)',
    '201-Tributada com permissão de crédito e cobrança do ICMS por ST (referente ao simples nacional)',
    '202-Tributada sem permissão de crédito e cobrança do ICMS por ST (referente ao simples nacional)',
    '203-Isenção do ICMS para a faixa de receita bruta com cobrança do ICMS por ST (referente ao simples nacional)',
    '300-Imune (referente ao simples nacional)',
    '400-Não tributada (referente ao simples nacional)',
    '500-ICMS cobrado anteriormente por ST ou por antecipação (referente ao simples nacional)',
    '900-Tributação ICMS pelo simples nacional (referente ao simples nacional)',
  ];
  const [operacoesFiscais, setOperacoesFiscais] = useState({});
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetOperacoesFiscais() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditOperacoesFiscais/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setOperacoesFiscais(data.OperacaoFiscal);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetOperacoesFiscais();
  }, [id]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditOperacoesFiscais/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      Object.keys(operacoesFiscais).length > 0
        ? operacoesFiscais
        : {
            Nome: '',
            SituacaoTributaria: '',
            DestinoEstado: '',
            PIS_ST: '',
            PIS: '',
            COFINS_ST: '',
            COFINS: '',
            ICMS_ST: '',
            ICMS_Interestadual: '',
            ICMS_UFDestino: '',
            ICMS_UFDestinoFCP: '',
            ICMSSomarIPIBase: '',
            CFOP: '',
          },
    validationSchema: yup.object({
      // Nome: yup.string().required('O campo é obrigatório.').nullable(),
      // SituacaoTributaria: yup.string().required('O campo é obrigatório.').nullable(),
      // DestinoEstado: yup.string().required('O campo é obrigatório.').nullable(),
      // PIS_ST: yup.string().required('O campo é obrigatório.').nullable(),
      // PIS: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // COFINS_ST: yup.string().required('O campo é obrigatório.').nullable(),
      // COFINS: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMS_ST: yup.string().required('O campo é obrigatório.').nullable(),
      // ICMS_Interestadual: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMS_UFDestino: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMS_UFDestinoFCP: yup.number().typeError('O valor deve ser um número').required('O campo é obrigatório.').nullable(),
      // ICMSSomarIPIBase: yup.boolean().required('O campo é obrigatório.').nullable(),
      // CFOP: yup.string().required('O campo é obrigatório.').nullable(),
    }),
    onSubmit: async values => {
      
      await save(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <form className="body" onSubmit={formik.handleSubmit}>
          <Flex
            flexGrow={1}
            id="content"
            justifyContent="center"
            minHeight="100%"
          >
            <Grid
              gridTemplateColumns="repeat(12, 1fr)"
              gap={'10px'}
              mx={6}
              width="100%"
              height="fit-content"
            >
              <Box p={4} gridColumn={gridColumn.gc12} className="searchbuttons">
                {/* <InputSearch placeholder="Procurar por operações fiscais" style={{ flex: 1 }} />  */}
                <Botao
                  variant="vermelho"
                  onClick={() => navigate('/OperacoesFiscais/Listar')}
                >
                  Cancelar
                </Botao>
                <Botao variant="turquesa" type="submit">
                  Salvar
                </Botao>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="NOVA OPERAÇÃO FISCAL" />
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>Operação</p>
                      <InputTexto
                        id="Nome"
                        name="Nome"
                        placeholder="Operação Fiscal"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Nome}
                      />
                      {formik.touched.Nome && formik.errors.Nome ? (
                        <div>{formik.errors.Nome}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>Situação Tributária</p>
                      <InputTexto
                        id="SituacaoTributaria"
                        name="SituacaoTributaria"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.SituacaoTributaria}
                      />
                      {formik.touched.SituacaoTributaria &&
                      formik.errors.SituacaoTributaria ? (
                        <div>{formik.errors.SituacaoTributaria}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>Estado Destino</p>
                      <InputSelect
                        id="DestinoEstado"
                        name="DestinoEstado"
                        placeholder="Selecione"
                        options={estados}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.DestinoEstado}
                      />
                      {formik.touched.DestinoEstado &&
                      formik.errors.DestinoEstado ? (
                        <div>{formik.errors.DestinoEstado}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="IMPOSTOS" />
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>PIS</p>
                      <InputSelect
                        id="PIS_ST"
                        name="PIS_ST"
                        options={optionsPIS}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PIS_ST}
                      />
                      {formik.touched.PIS_ST && formik.errors.PIS_ST ? (
                        <div>{formik.errors.PIS_ST}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>PIS(%)</p>
                      <InputNumInt
                        id="PIS"
                        name="PIS"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.PIS}
                      />
                      {formik.touched.PIS && formik.errors.PIS ? (
                        <div>{formik.errors.PIS}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>COFINS</p>
                      <InputSelect
                        id="COFINS_ST"
                        name="COFINS_ST"
                        options={optionsCOFINS}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.COFINS_ST}
                      />
                      {formik.touched.COFINS_ST && formik.errors.COFINS_ST ? (
                        <div>{formik.errors.COFINS_ST}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>COFINS</p>
                      <InputNumInt
                        id="COFINS"
                        name="COFINS"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.COFINS}
                      />
                      {formik.touched.COFINS && formik.errors.COFINS ? (
                        <div>{formik.errors.COFINS}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>ICMS</p>
                      <InputSelect
                        id="ICMS_ST"
                        name="ICMS_ST"
                        options={optionsICMS}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMS_ST}
                      />
                      {formik.touched.ICMS_ST && formik.errors.ICMS_ST ? (
                        <div>{formik.errors.ICMS_ST}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>ICMS Interestadual(%)</p>
                      <InputNumInt
                        id="ICMS_Interestadual"
                        name="ICMS_Interestadual"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMS_Interestadual}
                      />
                      {formik.touched.ICMS_Interestadual &&
                      formik.errors.ICMS_Interestadual ? (
                        <div>{formik.errors.ICMS_Interestadual}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>ICMS UF Destino(%)</p>
                      <InputNumInt
                        id="ICMS_UFDestino"
                        name="ICMS_UFDestino"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMS_UFDestino}
                      />
                      {formik.touched.ICMS_UFDestino &&
                      formik.errors.ICMS_UFDestino ? (
                        <div>{formik.errors.ICMS_UFDestino}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc4}>
                      <p>ICMS FCP(%)</p>
                      <InputNumInt
                        id="ICMS_UFDestinoFCP"
                        name="ICMS_UFDestinoFCP"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMS_UFDestinoFCP}
                      />
                      {formik.touched.ICMS_UFDestinoFCP &&
                      formik.errors.ICMS_UFDestinoFCP ? (
                        <div>{formik.errors.ICMS_UFDestinoFCP}</div>
                      ) : null}
                    </Box>
                    <Box p={1} gridColumn={gridColumn.gc3}>
                      <p>Somar IPI na Base</p>
                      <InputToggle
                        id="ICMSSomarIPIBase"
                        name="ICMSSomarIPIBase"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ICMSSomarIPIBase}
                      />
                      {formik.touched.ICMSSomarIPIBase &&
                      formik.errors.ICMSSomarIPIBase ? (
                        <div>{formik.errors.ICMSSomarIPIBase}</div>
                      ) : null}
                    </Box>
                  </Grid>
                </Flex>
              </Box>
              <Box mb={7} pb={10} gridColumn={gridColumn.gc12} className="card">
                <Flex flexGrow={1} id="content" justifyContent="center">
                  <Grid
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={'10px'}
                    width="100%"
                    height="fit-content"
                  >
                    <SecaoCadastro titulo="CFOP" />
                    <Box p={1} gridColumn={gridColumn.gc6}>
                      <p>CFOP</p>
                      <InputAutoComplete
                        id="CFOP"
                        name="CFOP"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.CFOP}
                      />
                      {formik.touched.CFOP && formik.errors.CFOP ? (
                        <div>{formik.errors.CFOP}</div>
                      ) : null}
                    </Box>
                    <Box mt={8} gridColumn={gridColumn.gc3}>
                      <Botao variant="laranja">Adicionar</Botao>
                    </Box>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </form>
      </Pagina>
    </>
  );
}

export default EditarOperacoesFiscais;
