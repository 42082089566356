import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Contratos.css';

function ListarContratos() {
  const navigate = useNavigate();
  const [contratos, setContratos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetContratos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListContratos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Contratos.forEach(b => {
          b.Selecionado = false;
        });
        setContratos(data.Contratos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetContratos();
  }, []);

  useEffect(() => {
    if (contratos && Object.keys(contratos).length > 0) {
    }
  }, [contratos]);

  function handleEdit(item) {
    navigate(`/Contratos/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Contratos/Editar');
  }


  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por contratos',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE CONTRATOS'}
            setFunction={setContratos}
            data={contratos}
            columns={[
              {
                placeholder: 'Cliente',
                name: 'Cliente',
                type: 'text',
              },
              {
                placeholder: 'Valor',
                name: 'Valor',
                type: 'money',
              },
              {
                placeholder: 'Data de Início',
                name: 'DataInicio',
                type: 'date',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarContratos;
