import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Filtros from '../../components/Filtros/Filtros';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import { getFiltroPadraoLancamentos } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import './LancamentosFinanceiros.css';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import Importacao from '../../components/Importacao/Importacao';
import Alerta from '../../components/Alerta/Alerta';

function ListarLancamentosFinanceiros() {
  const navigate = useNavigate();
  const [searchBar, setSearchBar] = useState('');
  const optionsData = [
    { value: 'vencimento', name: 'Regime de caixa (vencimento)' },
    { value: 'competencia', name: 'Filtrar Por Data de Competência' },
    { value: 'pagamento', name: 'Filtrar Por Data de Pagamento' },
  ];

  const optionsLancamentos = [
    { value: '', name: 'Tipo do lançamento' },
    { value: 'Receita', name: 'Receita' },
    { value: 'Despesa', name: 'Despesa' },
  ];

  const optionsSituacao = [
    { value: '', name: 'Situação' },
    { value: 'true', name: 'Pago' },
    { value: 'false', name: 'Não Pago' },
  ];
  const [lancamentos, setLancamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);

  const [importacao, setImportacao] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  async function GetLancamentos(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/ListLancamentos/GetList', payload);
    if (res.status === 200) {
      let data = res.data;
      data.Lancamentos.forEach(b => {
        b.Selecionado = false;
      });
      setLancamentos(data.Lancamentos);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    setShowFilters(search.get('filter') === 'true');
    GetLancamentos(getFiltroPadraoLancamentos());
  }, [search]);

  const uploadEmLote = async event => {
    const files = event.target.files;
    Array.from(files).forEach(async file => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        let res = await api.post(
          `EditLancamentos/ImportCSV?database=${localStorage.getItem(
            'database'
          )}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        let data = res.data;
        if (data.Success) {
          setAlertStatus('success');
          setAlertTitle('Arquivo carregado com sucesso');
          onOpen();
        } else if (!data.Success) {
          setAlertStatus('error');
          setAlertTitle(data.MSG);
          onOpen();
        }
      } catch (error) {}
    });

    return;
  };

  async function quitarEmLote() {
    setLoading(true);
    let payload = {
      data: JSON.stringify(lancamentos, null, 2),
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListLancamentos/QuitarLote', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      GetLancamentos(getFiltroPadraoLancamentos());
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }

  function handleClick() {
    navigate('/Lancamentos/Editar');
  }

  function handleEdit(item) {
    navigate(`/Lancamentos/Editar/${item.Id}`);
  }

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      {importacao ? (
        <Importacao
          tipo={'Lancamentos'}
          funcaoVoltar={() => setImportacao(false)}
          funcaoNovo={() => handleClick()}
          funcaoUpload={uploadEmLote}
        />
      ) : (
        <Pagina loading={loading}>
          <FormikPadrao
            initialValues={getFiltroPadraoLancamentos()}
            onSubmit={async values => {
              await GetLancamentos(values);
            }}
          >
            <BarraBotoes
              maxWindowSize={1030}
              botoes={[
                {
                  children: 'Quitar Selecionados',
                  onClick: quitarEmLote,
                  selecionados: true,
                  variant: 'azul',
                },
                {
                  children: 'Importar em Lote',
                  onClick: () => setImportacao(true),
                  variant: 'amarelo',
                },
                {
                  children: 'Novo',
                  onClick: handleClick,
                  variant: 'turquesa',
                },
              ]}
              search={{
                placeholder: 'Procurar por lançamentos financeiros',
                onChange: e => setSearchBar(e.target.value),
              }}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
            <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
              <InputFiltro
                titulo="Cliente"
                id="Cliente"
                name="Cliente"
                component={InputAutoComplete}
                autocomplete={true}
                path={'ClienteFornecedor'}
                database={localStorage.getItem('database')}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="EMPRESA"
                id="Empresa"
                name="Empresa"
                component={InputAutoComplete}
                autocomplete={true}
                path={'Empresa'}
                database={localStorage.getItem('database')}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="Banco"
                id="Banco"
                name="Banco"
                component={InputAutoComplete}
                autocomplete={true}
                path={'Bancos'}
                database={localStorage.getItem('database')}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="TIPO DO LANÇAMENTO"
                id="TipoLancamento"
                name="TipoLancamento"
                component={InputSelect}
                options={optionsLancamentos}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="SITUAÇÃO"
                id="Pago"
                name="Pago"
                component={InputSelect}
                options={optionsSituacao}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="FILTRAR POR"
                id="TipoData"
                name="TipoData"
                component={InputSelect}
                options={optionsData}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="Data Inicial"
                id="DataInicial"
                name="DataInicial"
                component={InputData}
                gridColumn={gridColumn.gc4}
              />
              <InputFiltro
                titulo="Data Final"
                id="DataFinal"
                name="DataFinal"
                component={InputData}
                gridColumn={gridColumn.gc4}
              />
            </Filtros>

            <Tabela
              titulo={'LISTAGEM DE LANÇAMENTOS FINANCEIROS'}
              setFunction={setLancamentos}
              data={lancamentos}
              columns={[
                {
                  placeholder: 'CÓDIGO',
                  name: 'Codigo',
                  type: 'text',
                },
                {
                  placeholder: 'EMPRESA',
                  name: 'Empresa',
                  type: 'text',
                },
                {
                  placeholder: 'CLIENTE/ FORNECEDOR',
                  name: 'Cliente',
                  type: 'text',
                },
                {
                  placeholder: 'TIPO',
                  name: 'Tipo',
                  type: 'text',
                },
                {
                  placeholder: 'VENCIMENTO',
                  name: 'DataVencimento',
                  type: 'date',
                },
                {
                  placeholder: 'VALOR',
                  name: 'Valor',
                  type: 'money',
                },
                {
                  placeholder: 'STATUS',
                  name: 'Pago',
                  type: 'function',
                  function: value => {
                    return value === true ? 'Pago' : 'Não Pago';
                  },
                },
                {
                  placeholder: 'OBSERVAÇÕES',
                  name: 'Observacoes',
                  type: 'text',
                },
              ]}
              searchBar={searchBar}
              handleEdit={handleEdit}
              useCheck={true}
            />
          </FormikPadrao>
        </Pagina>
      )}
    </>
  );
}

export default ListarLancamentosFinanceiros;
