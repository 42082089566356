import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import CardHeaderFooter from '../../components/Card/CardHeaderFooter';
import Botao from '../../components/Botao/Botao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputFormikSemLabel from '../../components/Inputs/InputFormik/InputFormikSemLabel';
import PaginaPDV from '../../components/PaginaPDV/PaginaPDV';
import './FrenteDeCaixa.css';
import api from '../../services/api';
import { getProdutosTeste } from './utils';
import InputDinheiro from '../../components/Inputs/InputDinheiro/InputDinheiro';

function FrenteDeCaixa() {
  const [loading, setLoading] = useState(true);
  const [caixa, setCaixa] = useState({
    Situacao: "Fechado"
  });
  const [pdv, setPdv] = useState({
    Quantidade: 0,
    Produto: "",
    ValorUnitario: 0,
    ValorTotal: 0,
  });

  const GetCaixa = useCallback(async () => {
    const { email } = JSON.parse(localStorage.getItem('user'));
    let payload = {
      database: localStorage.getItem('database'),
      arg: email
    };
    let res = await api.post('/PDV/GetDadosCaixa', payload);
    if (res.status === 200) {
      let data = res.data;
      console.log(data);
      setCaixa(data.caixa)
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }, []);

  useEffect(() => {
    GetCaixa();
  }, [GetCaixa]);

  async function AbrirCaixa() {
    const { email } = JSON.parse(localStorage.getItem('user'));
    let payload = {
      database: localStorage.getItem('database'),
      arg: email,
      arg2: 0, //TODO Valor de abertura
    };
    let res = await api.post('/PDV/AbrirCaixa', payload);
    if (res.status === 200) {
      let data = res.data;
      console.log(data);
      GetCaixa();
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }





  return (
    <PaginaPDV loading={loading} situacao={caixa.Situacao === "Fechado" ? "CAIXA FECHADO - " : "CAIXA ABERTO - "} >
      {
        caixa.Situacao === "Fechado" ?
          <>
            <Botao m={2} variant={'branco'} onClick={() => AbrirCaixa()}>
              ABRIR CAIXA (ENTER)
            </Botao></>
          :
          <Formik className="FormikFormPaginaPDV"
            enableReinitialize={true}
            initialValues={pdv}>
            <Interno setLoading={setLoading} GetCaixa={GetCaixa} />
          </Formik>
      }
    </PaginaPDV >
  );
}

function Interno({ setLoading, GetCaixa }) {

  const { values } = useFormikContext();
  const [produtos, setProdutos] = useState(getProdutosTeste());

  async function FecharCaixa() {
    const { email } = JSON.parse(localStorage.getItem('user'));
    let payload = {
      database: localStorage.getItem('database'),
      arg: email,
    };
    let res = await api.post('/PDV/FecharCaixa', payload);
    if (res.status === 200) {
      let data = res.data;
      console.log(data);
      GetCaixa();
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function getProduto() {

    let payload = {
      database: localStorage.getItem('database'),
      data: values.Produto
    };
    let res = await api.post('/PDV/GetProduto', payload);
    if (res.status === 200) {
      let data = res.data;
      console.log(data);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  return (

    <Form className="FormikFormPaginaPDV">
      <Flex
        flexGrow={1}
        flexDirection="column"
        justifyContent="flex-start"
        width="100%"
        py={4}
        px={6}
        gap={3}
        className="PaginaPDV"
      >
        <Flex className="mainPDV" gap={3} overflow="hidden">
          <CardHeaderFooter
            maxWidth="700px"
            header={
              <Flex width="100%" justifyContent="space-between" px={2}>
                <Flex
                  width="fit-content"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <p className="textoVerde">Definir cliente (F2)</p>
                  <p>Sem cliente identificado</p>
                </Flex>
                <Flex
                  width="fit-content"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <p className="textoVerde">Definir vendedor (F4)</p>
                  <p>15993241055 | Meu vendedor</p>
                </Flex>
              </Flex>
            }
          >
            <InputFormik
              titulo="Produto"
              id="Produto"
              name="Produto"
              component={InputAutoComplete}
              path="ProdutoPDV"
              autocomplete={true}
              database={localStorage.getItem('database')}
            />
            <Flex>
              <Box width="50%" maxHeight="300px" maxWidth="300px">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/743974_e8486758a88942e58a058988290b253e~mv2.png/v1/fill/w_480,h_480,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/743974_e8486758a88942e58a058988290b253e~mv2.png"
                />
              </Box>
              <Flex flexDirection="column" flexGrow={1}>
                <InputFormik
                  titulo="Quantidade"
                  id="Quantidade"
                  name="Quantidade"
                  component={InputNumInt}
                />
                {/* <InputFormik
                      titulo="Código"
                      id="Código"
                      name="Código"
                      component={InputAutoComplete}
                      path="Código"
                      autocomplete={true}
                      database={localStorage.getItem('database')}
                    /> */}
                <InputFormik
                  titulo="Valor Unitário"
                  id="ValorUnitário"
                  name="ValorUnitario"
                  component={InputDinheiro}
                />
                <InputFormik
                  titulo="Valor Total"
                  id="ValorTotal"
                  name="ValorTotal"
                  component={InputDinheiro}
                />
                <Botao m={2} variant={'branco'} onClick={() => getProduto()}>
                  INCLUIR (ENTER)
                </Botao>
              </Flex>
            </Flex>
          </CardHeaderFooter>
          <CardHeaderFooter
            header={
              <Text color="var(--chakra-colors-errorColor)">
                Cancelar Item (F6)
              </Text>
            }
            footer={
              <Box>
                <Flex p={2} fontSize={20} justifyContent="space-between">
                  <Text as="b">Preço total:</Text>
                  <Text>R$10.000,00</Text>
                </Flex>
                <Flex direction="column">
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <Text p={2}>
                      <Text as="b">Itens:</Text> 4
                    </Text>
                    <Text p={2}>
                      <Text as="b">Quantidade:</Text> 4
                    </Text>
                    <Text p={2}>
                      <Text as="b">Devolução/Desconto:</Text> 4
                    </Text>
                  </Flex>
                  <Flex gap={2} flexWrap="wrap">
                    <Botao mt={2} variant={'branco'} flexGrow={1}>
                      {' '}
                      Finalizar venda (F3)
                    </Botao>
                    <Botao mt={2} variant={'branco'} flexGrow={1}>
                      {' '}
                      Finalizar com NFCe (F7)
                    </Botao>
                  </Flex>
                </Flex>
              </Box>
            }
          >
            <Table>
              <Thead
                position="sticky"
                top="0"
                zIndex="sticky"
                backgroundColor={'white'}
              >
                <Tr>
                  <Th width="35%" colSpan={3} p={2} pb={4} minW="120px">
                    Item
                  </Th>
                  <Th p={2} pb={4}>
                    Quantidade
                  </Th>
                  <Th p={2} pb={4}>
                    Peso
                  </Th>
                  <Th p={2} pb={4}>
                    Valor (R$)
                  </Th>
                  <Th p={2} pb={4}>
                    SubTotal (R$)
                  </Th>
                  <Th width="40px" p={0}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {produtos.map((produto, index) => (
                  <Tr key={index + produto.produto}>
                    <Td p={2} colSpan={3}>
                      <InputFormikSemLabel
                        titulo="Produto"
                        id="Produto"
                        name="Produto"
                        component={InputAutoComplete}
                        path="Produto"
                        autocomplete={true}
                        database={localStorage.getItem('database')}
                        value={produto.produto}
                      />
                    </Td>
                    <Td p={2}>
                      <InputFormikSemLabel
                        titulo="Quantidade"
                        id="Quantidade"
                        name="Quantidade"
                        component={InputAutoComplete}
                        path="Quantidade"
                        autocomplete={true}
                        database={localStorage.getItem('database')}
                        value={produto.quantidade}
                      />
                    </Td>
                    <Td p={2}>{produto.peso}</Td>
                    <Td p={2}>
                      <InputFormikSemLabel
                        titulo="ValorUnitario"
                        id="ValorUnitario"
                        name="ValorUnitario"
                        component={InputAutoComplete}
                        path="ValorUnitario"
                        autocomplete={true}
                        database={localStorage.getItem('database')}
                        value={produto.valorUnitario}
                      />
                    </Td>
                    <Td p={2}>
                      <InputFormikSemLabel
                        titulo="Subtotal"
                        id="Subtotal"
                        name="Subtotal"
                        component={InputAutoComplete}
                        path="Subtotal"
                        autocomplete={true}
                        database={localStorage.getItem('database')}
                        value={produto.subtotal}
                      />
                    </Td>
                    <Td p={2}>
                      <Botao
                        variant="removeItem"
                        type="button"
                        className="buttonnot"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => console.log('Excluido')}
                      >
                        X
                      </Botao>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardHeaderFooter>
        </Flex>
        <Flex gap={4} flexWrap="wrap">
          <Botao p={4} flexGrow={1} variant={'branco'}>
            DESCONTO
            <br />
            (F9)
          </Botao>
          <Botao p={4} flexGrow={1} variant={'branco'}>
            SUPRIMENTO E SANGRIA
            <br />
            (INS)
          </Botao>
          <Botao p={4} flexGrow={1} variant={'vermelho'}>
            CANCELAR OPERAÇÃO
            <br />
            (ESC)
          </Botao>
          <Botao p={4} flexGrow={1} variant={'vermelho'} onClick={() => FecharCaixa()}>
            FECHAR PDV
            <br />
            (F10)
          </Botao>
        </Flex>
      </Flex>
    </Form>
  )
}

export default FrenteDeCaixa;
