import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import './ArquivosRH.css';
import FormPadrao from './../../components/FormPadrao/FormPadrao';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import IconeDownload from '../../components/Icones/IconeDownload/IconeDownload';
import InputArquivos from './../../components/Inputs/InputArquivo/InputArquivos';
import { valor_inicial } from '../Empresas/utils';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import { baixarArquivoURL } from '../../utils/download';

function EditarArquivosRH() {
  const navigate = useNavigate();
  const [empresa, setEmpresa] = useState(valor_inicial);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [contUpload, setContUpload] = useState(0);

  let { id } = useParams();
  let titulo = '';
  let upload = true;

  useEffect(() => {
    async function GetArquivosRH() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditEmpresas/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setEmpresa(data.Empresa);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetArquivosRH();
  }, [id, alertTitle]);

  const uploadArquivos = async event => {
    const files = event.target.files;
    setContUpload(0);
    Array.from(files).forEach(async file => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        setContUpload(prevContUpload => prevContUpload + 1);
        let res = await api.post(
          `EditEmpresas/UploadFile?id=${
            empresa.Id
          }&database=${localStorage.getItem('database')}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        let data = res.data;
        setContUpload(prevContUpload => prevContUpload - 1);

        if (data.Success && contUpload <= 0) {
          setAlertStatus('success');
          setAlertTitle('Arquivos carregados com sucesso');
          onOpen();
          upload = !upload;
        } else if (!data.Success) {
          setAlertStatus('error');
          setAlertTitle(data.MSG);
          onOpen();
        }
      } catch (error) {
        setContUpload(prevContUpload => prevContUpload - 1);
      }
    });

    return;
  };

  const baixarArquivo = async (empresaId, nomeArquivo) => {
    let database = localStorage.getItem('database');
    const linkSource = `${database}/rhempresa/${empresaId}/${nomeArquivo}`;

    let payload = {
      database: localStorage.getItem('database'),
      data: linkSource,
    };
    baixarArquivoURL('/EditEmpresas/GetUrlDownload', payload, nomeArquivo);
  };

  const deleteItem = async empresa => {
    let database = localStorage.getItem('database');

    let payload = {
      database: database,
      data: empresa.Id,
      arg: selectedItem,
    };
    let res = await api.post('/EditEmpresas/DeleteFile', payload);
    let data = res.data;
    onCloseExcluir();

    setEmpresa(prevEmpresa => {
      return {
        ...prevEmpresa,
        ArquivosRH: prevEmpresa.ArquivosRH.filter(q => q !== selectedItem),
      };
    });

    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  function handleDelete(item) {
    setSelectedItem(item);
    setAlertTitle('Excluir');
    setMessage(`Deseja excluir o arquivo ${item}?`);
    onOpenExcluir();
  }

  return (
    <div>
      <>
        <Alerta
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          alertStatus={alertStatus}
          title={alertTitle}
        />
        <AlertaConfirmacao
          isOpen={isOpenExcluir}
          onClose={onCloseExcluir}
          description={message}
          title={alertTitle}
          onConfirm={() => deleteItem(empresa)}
        />
        <Pagina loading={loading}>
          <FormPadrao>
            <BarraBotoes
              botoes={[
                {
                  variant: 'vermelho',
                  onClick: () => navigate('/ArquivosRH/Listar'),
                  children: 'Cancelar',
                },
              ]}
              caminho={[
                { nome: 'Arquivos RH', path: '/ArquivosRH/Listar' },
                { nome: titulo },
              ]}
            />
            <Card titulo="Arquivos da empresa">
              <Box p={2} gridColumn={gridColumn.gc12}>
                <InputArquivos handleUpload={uploadArquivos} />
              </Box>

              <Box className="arquivosCadastrados" gridColumn={gridColumn.gc12}>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>NOME</Th>
                        <Th>BAIXAR</Th>
                        <Th>
                          <IconeDeletar />
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {empresa.ArquivosRH.map((arquivo, index) => (
                        <Tr key={index}>
                          <Td>{arquivo}</Td>
                          <Td>
                            <IconeDownload
                              onClick={() => baixarArquivo(empresa.Id, arquivo)}
                            />
                          </Td>
                          <Td>
                            <IconeDeletar
                              onClick={() => handleDelete(arquivo)}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </FormPadrao>
        </Pagina>
      </>
    </div>
  );
}

export default EditarArquivosRH;
