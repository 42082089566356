import React, { useEffect, useState } from 'react';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './ConsultaNotasCNPJ.css';
import { useDisclosure } from '@chakra-ui/react';
import Alerta from '../../components/Alerta/Alerta';

function ConsultaNotasCNPJ() {
  const [notas, setNotas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const [user, setUser] = useState('');
  const emailUser = localStorage.getItem('user');

  useEffect(() => {
    if (emailUser) {
      const { email } = JSON.parse(emailUser);
      setUser(email);
    }
  }, [emailUser]);

  async function GetNotasEmitidasContra() {
    let payload = {
      database: localStorage.getItem('database'),
      data: '{}',
    };
    let res = await api.post('/ListNFe/GetNotasEmitidasContra', payload);
    let data = res.data;

    if (res.status === 200) {
      setNotas(data.NFes);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }
  useEffect(() => {
    GetNotasEmitidasContra();
  }, []);

  const baixarDocumento = async item => {
    setLoading(true);

    const linkSource = 'data:application/xml,' + encodeURIComponent(item.XML);
    const downloadLink = document.createElement('a');
    const fileName = item.Id + '.xml';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setLoading(false);
  };

  async function CarregarNovasNotas() {
    setLoading(true);

    let payload = {
      database: localStorage.getItem('database'),
      arg2: user,
    };
    let res = await api.post('/ListNFe/GetNotasEmitidasContraCNPJ', payload);
    let data = res.data;

    if (res.status === 200) {
      console.log(data)
      if (data.Retorno.Success) {
        setAlertStatus('success');
        setAlertTitle(data.Retorno.ReturnIfSuccess);
        onOpen();
      } else {
        setAlertStatus('error');
        setAlertTitle(data.Retorno.ReturnIfFail);
        onOpen();
      }
      GetNotasEmitidasContra();
    } else {
      alert('Erro ao buscar dados');
    }
  }

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                variant: 'turquesa',
                onClick: () => CarregarNovasNotas(),
                children: 'Baixar novas Notas',
              },
            ]}
            search={{
              placeholder: 'Procurar por notas emitidasa',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            useCheck={false}
            handleDownload={baixarDocumento}
            titulo={'LISTAGEM DE NFe EMITIDAS CONTRA CNPJ'}
            setFunction={setNotas}
            data={notas}
            columns={[
              {
                placeholder: 'Empresa',
                name: 'Empresa',
                type: 'text',
              },
              {
                placeholder: 'Emissor',
                name: 'Emissor',
                type: 'text',
              },
              {
                placeholder: 'Número NFe',
                name: 'NumeroNFe',
                type: 'text',
              },
              {
                placeholder: 'Data Emissão',
                name: 'DataEmissao',
                type: 'date',
              },
              {
                placeholder: 'Valor',
                name: 'ValorNFe',
                type: 'money',
              },
            ]}
            searchBar={searchBar}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ConsultaNotasCNPJ;
