import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import {
  getFiltroPadraoRelatorioCentroCustos,
} from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { baixarArquivoBase64 } from '../../utils/download';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputData from '../../components/Inputs/InputData/InputData';
import { isNotNullOrEmpty } from '../../utils/utils';

function RelCentroCustos() {
  const [relCentroCustos, setRelCentroCustos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  async function GetRelCentroCustos(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/CentroCustosReport/GetList', payload);
    let data = res.data;
    console.log(data)
    if (res.status === 200) {
      setRelCentroCustos(data.Lancamentos);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelCentroCustos(getFiltroPadraoRelatorioCentroCustos());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelCentroCustos(getFiltroPadraoRelatorioCentroCustos());
    }
  }, [search]);


  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relCentroCustos, null, 2),
    };
    baixarArquivoBase64('/CentroCustosReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(RelCentroCustos, null, 2),
    };
    baixarArquivoBase64('/CentroCustosReport/GetExcel', payload);
  };


  return (
      <Pagina loading={loading}>
        <FormikPadrao
          initialValues={getFiltroPadraoRelatorioCentroCustos()}
          onSubmit={async values => {
            await GetRelCentroCustos(values);
          }}
        >
          <BarraBotoes
            maxWindowSize={1030}
            botoes={[
              {
                children: 'Exportar Planilha',
                onClick: getExcel,
                variant: 'verde',
              },
              {
                children: 'Exportar PDF',
                onClick: getPDF,
                variant: 'rosa',
              }
            ]}
            search={{
              placeholder: 'Procurar por centro de custos',
              onChange: e => setSearchBar(e.target.value),
            }}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
          <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
            <InputFiltro
              titulo="EMPRESA"
              id="Empresa"
              name="Empresa"
              component={InputAutoComplete}
              autocomplete={true}
              path={'Empresa'}
              database={localStorage.getItem('database')}
              gridColumn={gridColumn.gc4}
            />
            <InputFiltro
              titulo="Data Inicial"
              id="DataInicial"
              name="DataInicial"
              component={InputData}
              gridColumn={gridColumn.gc4}
            />
          </Filtros>

          <Tabela
            titulo={'RELATÓRIO DE CENTROS DE CUSTOS'}
            setFunction={setRelCentroCustos}
            data={relCentroCustos}
            columns={[
              {
                placeholder: 'CENTRO DE CUSTOS',
                name: 'CentroCustos',
                type: 'function',
                function: value => {
                  return isNotNullOrEmpty(value) ? value : 'Sem Centro de Custos';
                },
              },
              {
                placeholder: 'PREVISTO',
                name: 'ValorTotal',
                type: 'money',
              },
              {
                placeholder: 'REALIZADO',
                name: 'ValorPago',
                type: 'money',
              },
            ]}
            searchBar={searchBar}
          />
        </FormikPadrao>
      </Pagina>
  );
}

export default RelCentroCustos;
