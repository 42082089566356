import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Empresas.css';

function ListarEmpresas() {
  const navigate = useNavigate();
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetEmpresas() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListEmpresas/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Empresas.forEach(b => {
          b.Selecionado = false;
        });
        setEmpresas(data.Empresas);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetEmpresas();
  }, []);

  function handleEdit(item) {
    navigate(`/Empresas/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Empresas/Editar');
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por empresas',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE EMPRESAS'}
            setFunction={setEmpresas}
            data={empresas}
            columns={[
              {
                placeholder: 'RAZÃO SOCIAL',
                name: 'RazaoSocial',
                type: 'text',
              },
              {
                placeholder: 'NOME FANTASIA',
                name: 'NomeFantasia',
                type: 'text',
              },
              {
                placeholder: 'CNPJ',
                name: 'CNPJ',
                type: 'text',
              },
              {
                placeholder: 'E-MAIL',
                name: 'Email',
                type: 'text',
              },
              {
                placeholder: 'TELEFONE',
                name: 'Telefone',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarEmpresas;
