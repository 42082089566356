import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Boletos.css';
import AlertaCampo from '../../components/AlertaCampo/AlertaCampo';
import { useDisclosure } from '@chakra-ui/react';
import Alerta from '../../components/Alerta/Alerta';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import { gridColumn } from '../../utils/gridColumn';
import { getFiltroPadraoBoletos } from '../../utils/filtros';

function ListarBoletos() {
  const navigate = useNavigate();
  const [boletos, setBoletos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenRemessa,
    onOpen: onOpenRemessa,
    onClose: onCloseRemessa,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('Mensagem');
  const message = 'Digite o número da remessa: ';
  const [showFilters, setShowFilters] = useState(false);


  async function GetBoletos(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/ListBoletos/Get', payload);

    if (res.status === 200) {
      let data = res.data;
      data.Boletos.forEach(b => {
        b.Selecionado = false;
      });
      setBoletos(data.Boletos);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetBoletos({});
  }, []);

  function handleClick() {
    navigate('/Boletos/Editar');
  }

  function handleEdit(item) {
    navigate(`/Boletos/Editar/${item.Id}`);
  }

  async function gerarRemessa(numeroRemessa) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(boletos, null, 2),
      arg: numeroRemessa
    };
    let res = await api.post('/ListBoletos/GerarRemessa', payload);

    if (res.status === 200) {
      onCloseRemessa();

      if (res.data.Success) {
        var pdf = res.data.Remessa;

        const linkSource = `data:text/plain;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "remessa.REM";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      else {
        setAlertStatus('error');
        setAlertTitle(res.data.MSG);
        onOpen();
      }
      setLoading(false);
    } else {
      alert('Erro ao gerar remessa');

    }
  }

  function HandlePlaceholderRemessa(numeroRemessa) {
    if (numeroRemessa) {
      return numeroRemessa
    } else {
      return 'Não gerada'
    }
  }

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <AlertaCampo
        isOpen={isOpenRemessa}
        onClose={onCloseRemessa}
        description={message}
        title={alertTitle}
        onConfirm={gerarRemessa}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          initialValues={getFiltroPadraoBoletos()}
          onSubmit={async values => {
            GetBoletos(values)
          }}
        >
          <BarraBotoes
            botoes={[
              {
                children: 'Gerar Remessa',
                onClick: () => onOpenRemessa(),
                variant: 'amarelo',
              },
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },

            ]}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            search={{
              placeholder: 'Procurar por boletos',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
          <Tabela
            titulo={'LISTAGEM DE BOLETOS'}
            setFunction={setBoletos}
            data={boletos}
            columns={[
              {
                placeholder: 'NOSSO NÚMERO',
                name: 'Boleto.NossoNumero',
                type: 'text',
              },
              {
                placeholder: 'VALOR',
                name: 'Boleto.ValorTitulo',
                type: 'money',
              },
              {
                placeholder: 'REMESSA',
                name: 'NumeroRemessaEnviada',
                type: 'function',
                function: HandlePlaceholderRemessa,
              },
              {
                placeholder: 'CLIENTE',
                name: 'Cliente',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
              {
                placeholder: 'BANCO',
                name: 'Banco',
                type: 'text',
              },
              {
                placeholder: 'VENCIMENTO',
                name: 'Boleto.DataVencimento',
                type: 'date',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            useCheck={true}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="REMESSA GERADA?"
        id="RemessaEnviada"
        name="RemessaEnviada"
        component={InputToggle}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default ListarBoletos;
