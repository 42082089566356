import React, { useEffect, useState } from 'react';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './CartasCorrecao.css';

function ListarCartasCorrecao() {
  const [cartas, setCartas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetCartasCorrecao() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListNFe/GetCartasCorrecao', payload);
      let data = res.data;

      if (res.status === 200) {
        setCartas(data.Cartas);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetCartasCorrecao();
  }, []);

  const baixarDocumento = async (item) => {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(item.Id),
    };
    let res = await api.post('/EditNFe/GetPDFCartaCorrecao', payload);
    let data = res.data;

    var pdf = data.MensagemRetornoSEFAZ;

    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'carta_correcao.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setLoading(false);
  };



  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[]}
            search={{
              placeholder: 'Procurar por cartas de correção',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            useCheck={false}
            handleDownload={baixarDocumento}
            titulo={'LISTAGEM DE CARTAS DE CORREÇÃO'}
            setFunction={setCartas}
            data={cartas}
            columns={[
              {
                placeholder: 'Lote',
                name: 'IdLote',
                type: 'text',
              },
              {
                placeholder: 'EVENTO',
                name: 'SequenciaEvento',
                type: 'text',
              },
              {
                placeholder: 'NFe referenciada',
                name: 'Chave',
                type: 'text',
              },
              {
                placeholder: 'Justificativa',
                name: 'Justificativa',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarCartasCorrecao;
