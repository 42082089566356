import React, { useEffect, useState } from 'react';
import LogoERP from '../../../images/logo.png';
import './HeaderPDV.css';

function HeaderPDV({situacao}) {
  const [user, setUser] = useState('emaildefault@email.com');
  const emailUser = localStorage.getItem('user');

  useEffect(() => {
    if (emailUser) {
      const { email } = JSON.parse(emailUser);
      setUser(email);
    }
  }, [emailUser]);

  return (
    <div className="headerPDV">
      <div className="logoContainer">
        <img className="headerlogo" src={LogoERP} alt="Logo ERP" />
      </div>
      <h2>{situacao} {user}</h2>
      <button
        type="button"
        className="buttonnot"
        // onClick={}
      >
        Usuário
      </button>
    </div>
  );
}

export default HeaderPDV;
