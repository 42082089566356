import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Marcas.css';

function ListarMarcas() {
  const navigate = useNavigate();
  const [marcas, setMarcas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    async function GetMarcas() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListMarcas/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Marcas.forEach(b => {
          b.Selecionado = false;
        });
        setMarcas(data.Marcas);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetMarcas();
  }, []);

  function handleEdit(item) {
    navigate(`/Marcas/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Marcas/Editar');
  }

  function handleDelete(item) {
    setSelectedItem(item.Id);
    setAlertTitle('Excluir');
    setMessage('Deseja excluir a marca?');
    onOpenExcluir();
  }

  let deleteItem = async () => {
    let payload = {
      data: selectedItem,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListMarcas/Delete', payload);
    let data = res.data;
    onCloseExcluir();
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setMarcas(marcas.filter(item => item.Id !== selectedItem));
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <AlertaConfirmacao
        isOpen={isOpenExcluir}
        onClose={onCloseExcluir}
        description={message}
        title={alertTitle}
        onConfirm={() => deleteItem()}
      />
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por bancos',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE MARCAS'}
            setFunction={setMarcas}
            data={marcas}
            columns={[
              {
                placeholder: 'NOME',
                name: 'Nome',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            useCheck={false}
            handleDelete={handleDelete}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarMarcas;
