import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import AlertaConfirmacao from '../../components/AlertaConfirmacao/AlertaConfirmacao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Cheques.css';

function ListarCheques() {
  const navigate = useNavigate();
  const [cheques, setCheques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const {
    isOpen: isOpenExcluir,
    onOpen: onOpenExcluir,
    onClose: onCloseExcluir,
  } = useDisclosure({
    defaultIsOpen: false,
  });
  const [alertTitle, setAlertTitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  async function GetCheques() {
    let payload = {
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListCheques/Get', payload);
    if (res.status === 200) {
      let data = res.data;
      data.Cheques.forEach(b => {
        b.Selecionado = false;
      });
      setLoading(false);
      setCheques(data.Cheques);
    } else {
      alert('Erro ao buscar dados');
    }
  }
  
  useEffect(() => {
    GetCheques();
  }, []);

  function handleEdit(item) {
    navigate(`/Cheques/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Cheques/Editar');
  }

  function handleDelete(item) {
    setSelectedItem(item.Id);
    setAlertTitle('Excluir');
    setMessage('Deseja excluir o cheque?');
    onOpenExcluir();
  }

  let deleteItem = async () => {
    let payload = {
      data: selectedItem,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/ListCheques/Delete', payload);
    let data = res.data;
    onCloseExcluir();
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setCheques(cheques.filter(item => item.Id !== selectedItem));
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  function GetStatus(status) {
    switch (status) {
      case 'EmCaixa':
        return 'Em Caixa';
      case 'Depositado':
        return 'Depositado';
      case 'Devolvido':
        return 'Devolvido';
      case 'Guardado':
        return 'Guardado';
      default:
        return 'Em Caixa';
    }
  }
  return (
    <>
      <AlertaConfirmacao
        isOpen={isOpenExcluir}
        onClose={onCloseExcluir}
        description={message}
        title={alertTitle}
        onConfirm={() => deleteItem()}
      />
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por cheques',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE CHEQUES'}
            setFunction={setCheques}
            data={cheques}
            columns={[
              {
                placeholder: 'NÚMERO',
                name: 'Numero',
                type: 'text',
              },
              {
                placeholder: 'EMITENTE',
                name: 'Emitente',
                type: 'text',
              },
              {
                placeholder: 'FAVORECIDO',
                name: 'Favorecido',
                type: 'text',
              },
              {
                placeholder: 'BANCO',
                name: 'Banco',
                type: 'text',
              },
              {
                placeholder: 'DATA COMPENSAÇÃO',
                name: 'DataCompensacao',
                type: 'date',
              },
              {
                placeholder: 'VALOR',
                name: 'Valor',
                type: 'money',
              },
              {
                placeholder: 'SITUAÇÃO',
                name: 'Situacao',
                type: 'function',
                function: GetStatus,
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            useCheck={false}
            handleDelete={handleDelete}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarCheques;
