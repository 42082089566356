import React from 'react';
import './InputData.css';

import { Input } from '@chakra-ui/react';

function InputData({ placeholder, local = false, ...rest }) {
  return (
    <Input
      className="inputdata"
      type={local ? 'datetime-local' : 'date'}
      {...rest}
    />
  );
}

export default InputData;
