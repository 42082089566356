import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Bancos.css';

function ListarBancos() {
  const [bancos, setBancos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetBancos() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListBancos/Get', payload);
      let data = res.data;
      data.Bancos.forEach(b => {
        b.Selecionado = false;
      });
      if (res.status === 200) {
        setBancos(data.Bancos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetBancos();
  }, []);

  const navigate = useNavigate();

  function handleClick() {
    navigate('/Bancos/Editar');
  }

  function handleEdit(item) {
    navigate(`/Bancos/Editar/${item.Id}`);
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por bancos',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE BANCOS'}
            setFunction={setBancos}
            data={bancos}
            columns={[
              {
                placeholder: 'NOME',
                name: 'Nome',
                type: 'text',
              },
              {
                placeholder: 'TIPO DE CONTA',
                name: 'TipoConta',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarBancos;
