import React from 'react';
import { RiFileEditFill } from 'react-icons/ri';
import './IconeEditar.css';

function IconeEditar () {
  return (
    <RiFileEditFill className="iconeEditar" />
  );
}

export default IconeEditar;
