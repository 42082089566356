const valor_inicial = {
  Nome: '',
  TipoConta: '',
  SaldoInicial: 0,
  EmpresaId: '',
  Empresa: '',
  EmiteBoletos: false,
  CodigoCedente: '',
  DigitoCedente: '',
  DetalhesConta: {
    TipoCarteiraPadrao: 1,
    CarteiraPadrao: '',
    VariacaoCarteiraPadrao: '',
    CarteiraComVariacaoPadrao: '',
    Agencia: '',
    DigitoAgencia: '',
    Conta: '',
    DigitoConta: '',
    OperacaoConta: '',
    TipoFormaCadastramento: 1,
    TipoImpressaoBoleto: 2,
    TipoDocumento: 1,
    LocalPagamento: 'PAGÁVEL EM QUALQUER BANCO.',
    MensagemFixaTopoBoleto: '',
    MensagemFixaSacado: '',
    CodigoBancoCorrespondente: 0,
    NossoNumeroBancoCorrespondente: null,
    TipoDistribuicao: 2,
  },
  Agencia: '',
  Numero: '',
  DigitoConta: '',
  DigitoAgencia: '',
  NumeroBanco: '',
  NossoNumeroInicial: 0,
};

const optionsContas = [
  { value: '', name: 'Selecione' },
  { value: 'Conta Corrente', name: 'Conta Corrente' },
  { value: 'Poupanca', name: 'Poupança' },
  { value: 'Investimento', name: 'Investimento' },
  { value: 'Caixa Empresa', name: 'Caixa Empresa' },
  { value: 'Meios de Recebimento', name: 'Meios de Recebimento' },
  { value: 'Outros', name: 'Outros' },
];
const optionsNumBancos = [
  { value: '', name: 'Selecione' },
  { value: '41', name: '041- Banrisul' },
  { value: '1', name: '001- Banco do Brasil' },
  { value: '237', name: '237- Bradesco' },
  { value: '341', name: '341- Itaú' },
  { value: '033', name: '033- Santander' },
  { value: '748', name: '748- Sicred' },
  { value: '104', name: '104- Caixa Econômica Federal' },
  { value: '422', name: '422- Safra' },
  { value: '756', name: '756- Sicoob' },
  { value: '136', name: '136- Unicred' },
  { value: '2', name: '02- Outros' },
];

const optionsCarteira = {
  41: [{ value: '1', name: '1' }],
  1: [{ value: '17', name: '17' }],
  104: [{ value: 'SIG14', name: 'SIG14' }],
  237: [{ value: '09', name: '09' }],
  136: [{ value: '09', name: '09' }],
  341: [
    { value: '109', name: '109' },
    { value: '112', name: '112' },
  ],
  422: [
    { value: '1', name: '1' },
    { value: '2', name: '2' },
  ],
  33: [{ value: '101', name: '101' }],
  756: [{ value: '1', name: '1-01' }],
  748: [{ value: '1/A', name: '1-A' }],
};

const optionsVariacaoCarteira = [
  { value: '109', name: '109' },
  { value: '027', name: '027' },
];

export {
    optionsCarteira, optionsContas,
    optionsNumBancos, optionsVariacaoCarteira, valor_inicial
};

