import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronUpDown from '../../Icones/ChevronUpDown/ChevronUpDown';
import '../MenuLateral.css';

function ItemMenuConfig({ titulo, Icone, itens }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(
    new Array(itens.lenght).fill(false, 0)
  );

  function UpdateMenu(index) {
    let temp = [...showSubMenu];
    temp[index] = !temp[index];
    setShowSubMenu(temp);
  }
  
  return (
    <>
      <button
        className="linkmenu"
        type="button"
        onClick={() => setShowMenu(!showMenu)}
      >
        <Icone className="icone" />
        {titulo} <ChevronUpDown down={!showMenu} />
      </button>
      {showMenu && (
        <>
          {itens && (
            <>
              {itens.map((item, index) => (
                <>
                  <button
                    type="button"
                    className="linkinterno"
                    onClick={() => UpdateMenu(index)}
                  >
                    {item.nome} <ChevronUpDown down={!showSubMenu[index]} />
                  </button>
                  {showSubMenu[index] && (
                    <div className="linksmenuinternorel">
                      {item.itens.map(item => (
                        <Link
                          key={item.url}
                          to={item.url}
                          className="linkinterno"
                        >
                          {item.nome}
                        </Link>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ItemMenuConfig;
