import { Box } from '@chakra-ui/react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import './InputFormik.css'

function InputFormik({
  titulo,
  gridColumn,
  component,
  autocomplete = false,
  ...rest
}) {
  const formik = useFormikContext();

  if (autocomplete) {
    return (
      <Box p={2} gridColumn={gridColumn}>
        <p className='textoInputFormik'>{titulo}</p>
        <Field size='sm' as={component} onSourceSelect={formik.setFieldValue} {...rest} />
        <ErrorMessage name={rest.name} />
      </Box>
    );
  }
  return (
    <Box p={2} gridColumn={gridColumn}>
      <p className='textoInputFormik'>{titulo}</p>
      <Field size='sm' as={component} {...rest} />
      <ErrorMessage name={rest.name} />
    </Box>
  );
}

export default InputFormik;
