const getButtonNovoText = category => {
  switch (category) {
    case 'Clientes':
      return 'Novo Cliente';
    case 'Fornecedores':
      return 'Novo Fornecedor';
    case 'Transportadoras':
      return 'Nova Transportadora';
    case 'Funcionarios':
      return 'Novo Funcionário';
    case 'Vendedores':
      return 'Novo Vendedor';
    default:
      return '';
  }
};

const getButtonEditarText = (category, id) => {
  if (id) {
    switch (category) {
      case 'Clientes':
        return 'Editar Cliente';
      case 'Fornecedores':
        return 'Editar Fornecedor';
      case 'Transportadoras':
        return 'Editar Transportadora';
      case 'Funcionarios':
        return 'Editar Funcionário';
      case 'Vendedores':
        return 'Editar Vendedor';
      default:
        return '';
    }
  } else {
    switch (category) {
      case 'Clientes':
        return 'Novo Cliente';
      case 'Fornecedores':
        return 'Novo Fornecedor';
      case 'Transportadoras':
        return 'Nova Transportadora';
      case 'Funcionarios':
        return 'Novo Funcionário';
      case 'Vendedores':
        return 'Novo Vendedor';
      default:
        return '';
    }
  }
};

const optionsIndicadorIE = [
  { value: 'NaoContribuinte', name: 'Não Contribuinte' },
  { value: 'Contribuinte', name: 'Contribuinte' },
  { value: 'ContribuinteIsento', name: 'Contribuinte Isento' },
];
const optionsTipoComissao = [
  { value: '0', name: 'Percentual' },
  { value: '1', name: 'Valor' },
];
const optionsDiaMes = [
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
  { value: '10', name: '10' },
  { value: '11', name: '11' },
  { value: '12', name: '12' },
  { value: '13', name: '13' },
  { value: '14', name: '14' },
  { value: '15', name: '15' },
  { value: '16', name: '16' },
  { value: '17', name: '17' },
  { value: '18', name: '18' },
  { value: '19', name: '19' },
  { value: '20', name: '20' },
  { value: '21', name: '21' },
  { value: '22', name: '22' },
  { value: '23', name: '23' },
  { value: '24', name: '24' },
  { value: '25', name: '25' },
  { value: '26', name: '26' },
  { value: '27', name: '27' },
  { value: '28', name: '28' },
  { value: '29', name: '29' },
  { value: '30', name: '30' },
  { value: '31', name: '31' },
];

const getValorInicial = category => {
  return {
    NomeFantasia: '',
    RazaoSocial: '',
    IE: '',
    IndicadorIE: 'NaoContribuinte',
    IM: '',
    Categoria: category,
    EhFisica: true,
    CPF_CNPJ: '',
    DataNascimento: '',
    Telefone: '',
    TelefoneComercial: '',
    Email: '',
    EmailComercial: '',
    EmailFinanceiro: '',
    Site: '',
    Contato: '',
    CondicaoPagamento: '',
    CondicaoPagamentoId: '',
    CategoriaVenda: '',
    CategoriaVendaId: '',
    TabelaPreco: '',
    TabelaPrecoId: '',
    RuaEndereco: '',
    NumeroEndereco: '',
    ComplementoEndereco: '',
    BairroEndereco: '',
    CidadeEndereco: '',
    CodigoCidadeEndereco: 0,
    CEPEndereco: '',
    UFEndereco: '',
    CodigoUFEndereco: 0,
    PaisEndereco: '',
    CodigoPaisEndereco: '',
    Vendedor: {
      TipoComissao: 0,
      Percentual: 0,
      Valor: 0,
      DiaComissao: 0,
      Empresa: '',
      EmpresaId: '',
      TipoComissaoPDV: 0,
      PercentualPDV: 0,
      ValorPDV: 0,
      DiaComissaoPDV: 0,
      MetaDiaria: 0,
      MetaMensal: 0,
    },
    DataAdmissao: '2023-02-15',
    DataDesligamento: '0001-01-01',
    Salario: 0,
    Inativo: false,
    MaisDeUmMesmoNome: false,
    Id: '',
  };
};

export {
  getButtonEditarText,
  getButtonNovoText,
  optionsIndicadorIE,
  optionsTipoComissao,
  optionsDiaMes,
  getValorInicial,
};
