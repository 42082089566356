import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioOrdensProducao } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';

function RelOP() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relOP, setRelOP] = useState([]);

  async function GetRelOP(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/OrdensProducaoReport/GetList', payload);
    let data = res.data;

    setRelOP(data.Ordens);
    setLoading(false);
  }

  useEffect(() => {
    GetRelOP(getFiltroPadraoRelatorioOrdensProducao());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelOP(getFiltroPadraoRelatorioOrdensProducao());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/OrdensProducao/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relOP, null, 2),
    };
    baixarArquivoBase64('/OrdensProducaoReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relOP, null, 2),
    };
    baixarArquivoBase64('/OrdensProducaoReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioOrdensProducao()}
        onSubmit={async values => {
          await GetRelOP(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por ordens',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <Tabela
          titulo={'RELATÓRIO DE ORDENS DE PRODUÇÃO'}
          setFunction={setRelOP}
          data={relOP}
          columns={[
            {
              placeholder: 'ORDEM',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'PRODUTO',
              name: 'Produto',
              type: 'text',
            },
            {
              placeholder: 'QUANTIDADE',
              name: 'Quantidade',
              type: 'text',
            },
            {
              placeholder: 'DATA DE INÍCIO',
              name: 'DataInicio',
              type: 'date',
            },
            {
              placeholder: 'PREVISÃO DE ENTREGA',
              name: 'DataTermino',
              type: 'date',
            },
            {
              placeholder: 'STATUS',
              name: 'Status',
              type: 'text',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();
  const optionsData = [
    { value: 'Inicio', name: 'Data de Início' },
    { value: 'Entrega', name: 'Previsão de Entrega' },
  ];

  const optionsStatus = [
    { value: '', name: 'Status' },
    { value: '1', name: 'Rascunho' },
    { value: '2', name: 'Em Produção' },
    { value: '3', name: 'Pausada' },
    { value: '4', name: 'Cancelada' },
    { value: '5', name: 'Finalizada' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CÓDIGO"
        id="Codigo"
        name="Codigo"
        component={InputTexto}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="STATUS"
        id="Status"
        name="Status"
        component={InputSelect}
        options={optionsStatus}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelOP;
