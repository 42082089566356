import {
  Badge,
  Box,
  Grid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { GoCheck, GoX } from 'react-icons/go';
import '../../App.css';
import IconeDeletar from '../../components/Icones/IconeDeletar/IconeDeletar';
import IconeDownload from '../../components/Icones/IconeDownload/IconeDownload';
import IconeEditar from '../../components/Icones/IconeEditar/IconeEditar';
import InputCheckbox from '../../components/Inputs/InputCheckbox/InputCheckbox';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import { formataData, formataDinheiro } from '../../utils/formatador';
import { gridColumn } from '../../utils/gridColumn';
import { sortAZ, sortFunction, sortInt } from '../../utils/utils';
import './Tabela.css';
import ReactPaginate from 'react-paginate';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { useFormikContext } from 'formik';

function TabelaPaginada({
  setFunction,
  columns,
  titulo,
  useCheck = false,
  handleEdit = null,
  showDelete = true,
  handleDelete = null,
  handleDownload = null,
  mensagemNaoEncontrado = 'Nenhum dado encontrado!',
  tamanhoPaginacao = 20,
  getPaginatedData = null,
  totalItems = 0,
  currentPage = 1,
  setCurrentPage = null,
}) {
  const [desc, setDesc] = useState(false);
  const [data, setData] = useState([]);
  const [maxPaginas, setMaxPaginas] = useState(1);

  useEffect(() => {
    setMaxPaginas(Math.ceil(totalItems / tamanhoPaginacao));
  }, [totalItems, tamanhoPaginacao]);

  useEffect(() => {
    async function getData() {
      let page = currentPage > 0 ? currentPage : 1; 
      if (currentPage === 0) {
        let dataRes = await getPaginatedData(values, page);
        setData(dataRes);
      } else {
        let dataRes = await getPaginatedData(values, currentPage);
        setData(dataRes);
      }
    }
    getData();
  }, [currentPage]);

  const { values } = useFormikContext();

  columns = columns.filter(column => !column.hide);

  function handleDoubleClick(param, type = 'text', func = null) {
    setDesc(!desc);
    if (type === 'text') setFunction(sortAZ(data, desc, param));
    if (type === 'number') setFunction(sortInt(data, desc, param));
    if (type === 'money') setFunction(sortInt(data, desc, param));
    if (type === 'date') setFunction(sortAZ(data, desc, param));
    if (type === 'bool') setFunction(sortAZ(data, desc, param));
    if (type === 'function' && func)
      setFunction(sortFunction(data, desc, param, func));
  }

  function handleCheck(item, value) {
    let itens = data;
    for (let i = 0; i < itens.length; i++) {
      if (itens[i].Id === item.Id) {
        itens[i].Selecionado = value;
        break;
      }
    }
    setFunction([...itens]);
  }

  return (
    <Box mb={7} gridColumn={gridColumn.gc12} className="card">
      <SecaoCadastro titulo={titulo} />
      <Box className="cadastrados" gridColumn={gridColumn.gc12}>
        {data && data.length > 0 && (
          <>
            <TableContainer maxWidth={'100%'} whiteSpace={'revert'}>
              <Table>
                <Thead>
                  <Tr>
                    {(useCheck || handleEdit) && <Th></Th>}
                    {columns.map(column => (
                      <Th key={column.name}>
                        <button
                          onDoubleClick={() =>
                            handleDoubleClick(
                              column.name,
                              column.type,
                              column.function
                            )
                          }
                          type="button"
                        >
                          {column.placeholder.toUpperCase()}
                        </button>
                      </Th>
                    ))}
                    {showDelete && handleDelete && <Th></Th>}
                    {handleDownload && <Th></Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {data &&
                    data.length > 0 &&
                    data
                      //.filter(item => filtrar(item, searchBar))
                      .map(item => (
                        <Tr
                          key={
                            item.Id
                              ? item.Id + data.indexOf(item)
                              : data.indexOf(item)
                          }
                          className={`${
                            item.Selecionado ? 'checked' : 'notChecked'
                          }`}
                        >
                          {useCheck && (
                            <Td>
                              <InputCheckbox
                                item={item}
                                initialValue={item.Selecionado}
                                handleChange={handleCheck}
                              />
                              <button
                                onClick={() => handleEdit(item)}
                                style={{
                                  width: '15px',
                                }}
                              >
                                <IconeEditar />
                              </button>
                            </Td>
                          )}
                          {!useCheck && handleEdit && (
                            <Td>
                              <button
                                onClick={() => handleEdit(item)}
                                style={{
                                  width: '15px',
                                }}
                              >
                                <IconeEditar />
                              </button>
                            </Td>
                          )}
                          {columns.map(column => {
                            switch (column.type) {
                              case 'money':
                                return (
                                  <Td key={columns.indexOf(column)}>
                                    {formataDinheiro(get(item, column.name))}
                                  </Td>
                                );
                              case 'date':
                                return (
                                  <Td key={columns.indexOf(column)}>
                                    {formataData(get(item, column.name))}
                                  </Td>
                                );
                              case 'function':
                                return (
                                  <Td key={columns.indexOf(column)}>
                                    {column.function(get(item, column.name))}
                                  </Td>
                                );
                              case 'bool':
                                return (
                                  <Td key={columns.indexOf(column)}>
                                    {get(item, column.name) ? (
                                      <GoCheck />
                                    ) : (
                                      <GoX />
                                    )}
                                  </Td>
                                );
                              case 'badges':
                                return (
                                  <Td key={columns.indexOf(column)}>
                                    <Stack direction="row">
                                      {get(item, column.name).map(item => (
                                        <Badge colorScheme="purple">
                                          {item.Nome}
                                        </Badge>
                                      ))}
                                    </Stack>
                                  </Td>
                                );
                              default:
                                return (
                                  <Td key={columns.indexOf(column)}>
                                    {get(item, column.name)}
                                  </Td>
                                );
                            }
                          })}

                          {showDelete && handleDelete && (
                            <Td>
                              <button
                                onClick={() => handleDelete(item)}
                                style={{
                                  width: '15px',
                                }}
                                type="button"
                              >
                                <IconeDeletar />
                              </button>
                            </Td>
                          )}
                          {handleDownload && (
                            <Td>
                              <button
                                onClick={() => handleDownload(item)}
                                style={{
                                  width: '15px',
                                }}
                                type="button"
                              >
                                <IconeDownload />
                              </button>
                            </Td>
                          )}
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Box className="pagination-container">
              <ReactPaginate
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageClassName={'page-item'}
                onPageChange={async event => {
                  setCurrentPage(event.selected + 1);
                }}
                breakLabel="..."
                pageCount={maxPaginas}
                previousLabel={
                  <IconContext.Provider
                    value={{ color: '#B8C1CC', size: '36px' }}
                  >
                    <AiFillLeftCircle />
                  </IconContext.Provider>
                }
                nextLabel={
                  <IconContext.Provider
                    value={{ color: '#B8C1CC', size: '36px' }}
                  >
                    <AiFillRightCircle />
                  </IconContext.Provider>
                }
              />
            </Box>
          </>
        )}
        {!data ||
          (data.length === 0 && (
            <Box mb={5} gridColumn={gridColumn.gc12}>
              <Grid
                gridTemplateColumns="repeat(12, 1fr)"
                mx={1}
                width="100%"
                height="100%"
                maxBlockSize={'300px'}
                className="itensRelatorio"
              >
                <Box gridColumn={gridColumn.gc6} key={mensagemNaoEncontrado}>
                  {mensagemNaoEncontrado}
                </Box>
              </Grid>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default TabelaPaginada;
