import React from 'react';
import { RiDownload2Fill } from 'react-icons/ri';
import './IconeDownload.css';

function IconeDownload({ ...props }) {
  return (
    <button type="button" {...props}>
      <RiDownload2Fill className="iconeDownload" />
    </button>
  );
}

export default IconeDownload;
