import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoNFe } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import './NFe.css';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import Filtros from '../../components/Filtros/Filtros';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TabelaPaginada from './../../components/Tabela/TabelaPaginada';

function ListarNFe() {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const [NFe, setNFe] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  async function GetNFe(filtros, pagina) {
    setCurrentPage(pagina);
    filtros.DataInicial = new Date('0001-01-01').toISOString().slice(0, 10);
    filtros.DataFinal = new Date('0001-01-01').toISOString().slice(0, 10);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
      arg: pagina,
    };
    let res = await api.post('/ListNFe/GetPaginacao', payload);
    if (res.status === 200) {
      let data = res.data;
      data.NFes.forEach(b => {
        b.Selecionado = false;
      });
      /*let newNFe = NFe;
      newNFe[pagina] = data.NFes;
      setNFe(newNFe);    */
      setNFe(prevNFe => {
        prevNFe[pagina] = data.NFes;
        return prevNFe;
      });
      
      setLoading(false);
      setTotalItems(res.data.quantidade);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  async function getPaginatedData(filtros, pagina) {
    if (!NFe.hasOwnProperty(pagina)) {
      await GetNFe(filtros, pagina);
    }
    return NFe[pagina];
  }

  useEffect(() => {
    GetNFe(getFiltroPadraoNFe(), 1);
  }, []);

  function handleEdit(item) {
    navigate(`/NFe/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/NFe/Editar');
  }

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoNFe()}
        onSubmit={async values => {
          setNFe([]);
          setCurrentPage(0);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Novo',
              onClick: handleClick,
              variant: 'verde',
            },
          ]}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TabelaPaginada
          titulo={'NOTAS FISCAIS ELETRÔNICAS'}
          setFunction={setNFe}
          columns={[
            {
              placeholder: 'EMISSOR',
              name: 'Emissor',
              type: 'text',
            },
            {
              placeholder: 'DESTINATÁRIO',
              name: 'DadosNota.Cliente.Nome',
              type: 'text',
            },
            {
              placeholder: 'NÚMERO',
              name: 'NumeroNFe',
              type: 'text',
            },
            {
              placeholder: 'SÉRIE',
              name: 'SerieNFe',
              type: 'text',
            },
            {
              placeholder: 'VALOR',
              name: 'ValorNota',
              type: 'money',
            },
            {
              placeholder: 'ORIGEM',
              name: 'Origem',
              type: 'date',
            },
            {
              placeholder: 'STATUS',
              name: 'StatusSefaz',
              type: 'function',
              function: item => {
                return item.includes('Autorizado o uso')
                  ? 'Autorizada'
                  : item.includes('Cancelamento') || item.includes('Cancelada')
                  ? 'Cancelada'
                  : 'Não autorizada';
              },
            },
            {
              placeholder: 'EMISSÃO',
              name: 'DataStatusSefaz',
              type: 'date',
            },
          ]}
          searchBar={''}
          handleEdit={handleEdit}
          totalItems={totalItems}
          getPaginatedData={getPaginatedData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMISSOR"
        id="Emissor"
        name="Emissor"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="DESTINATÁRIO"
        id="Destinatario"
        name="Destinatario"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="NÚMERO"
        id="Codigo"
        name="Codigo"
        component={InputNumInt}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="VALOR"
        id="Valor"
        name="Valor"
        component={InputFloat}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default ListarNFe;
