import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import BgLogin from '../../images/bg-login.png';
import LogoERP from '../../images/logo-erp-colorido.png';
import api from '../../services/api';
import './Login.css';

function Login() {
  const [disabled, setDisabled] = useState(false);
  let navigate = useNavigate();
  const [user, setUser] = useState({
    email: '',
  });
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  async function loginUser() {
    let payload = {
      arg: user.email,
      data: password,
    };
    let res = await api.post('/Login/LoginTemporario', payload);
    localStorage.setItem('user', JSON.stringify(user));
    setLoading(false);
    return res.data;
  }

  function handleOnChange({ target }) {
    const { type, value } = target;
    if (type === 'email') {
      setUser({
        email: value,
      });
    } else {
      setPassword(value);
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const data = await loginUser();
    localStorage.setItem('database', data.database);
    setUser({
      email: '',
    });
    setPassword('');
    navigate('/Dashboard');
  };

  function handleSubmitCriar() {
    history.push('/home');
  }

  // function handleSubmitEsqueci() {
  //   history.push('/home');
  // }

  useEffect(() => {
    const validateButton = () => {
      const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/i;
      const minCharacters = 7;

      if (user.email.match(emailRegex) && password.length >= minCharacters) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    validateButton();
  }, [user, password]);

  return (
    <>
      {!loading && (
        <Flex
          flexGrow={1}
          id="content"
          height="100%"
          width="100%"
          className="loginScreen"
          minHeight="100vh"
        >
          <div
            className="bgLateral"
            style={{ backgroundImage: `url(${BgLogin})` }}
          >
            <h2 className="tituloLogin">
              VOCÊ ESTÁ A UM PASSO DE DAR O PRÓXIMO PASSO
            </h2>
            {/* <ColorModeSwitcher /> */}
          </div>
          <form onSubmit={handleSubmit} className="formSignin">
            <img className="logoERP" src={LogoERP} alt="Logo ERP" />
            <label htmlFor="inputEmail" className="hide">
              Endereço de email
            </label>
            <input
              type="email"
              id="inputEmail"
              placeholder="Seu email"
              required
              autoFocus
              value={user.email}
              onChange={handleOnChange}
            />
            <label htmlFor="inputPassword" className="hide">
              Senha
            </label>
            <input
              type="password"
              id="inputPassword"
              placeholder="Senha"
              required
              value={password}
              onChange={handleOnChange}
            />
            <label htmlFor="rememberMe" className="checkbox">
              <input type="checkbox" value="rememberMe" id="rememberMe" />{' '}
              Esqueceu a Senha?
            </label>
            <button className="btGeralEntrar" type="submit">
              ENTRAR
            </button>
            <p className="separadorOu">-------ou-------</p>
            <button
              className="btCriar"
              type="submit"
              onClick={handleSubmitCriar}
              disabled={disabled}
            >
              CRIAR CONTA
            </button>
          </form>
        </Flex>
      )}
      {loading &&
        <Flex 
          className="paginaLogin"
          minHeight="100vh"
          alignItems='center'
          justifyContent='center'
        >
          <Loader />
        </Flex>}
    </>
  );
}

export default Login;
