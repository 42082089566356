import { Box, Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import { gridColumn } from '../../utils/gridColumn';

import '../../App.css';

function Card({ titulo, children, className }) {
  return (
    <Box
      mb={7}
      pb={6}
      gridColumn={gridColumn.gc12}
      className={`card ${className}`}
    >
      <Flex flexGrow={1} flexDirection="column" id="content" justifyContent="center">
        <SecaoCadastro titulo={titulo.toUpperCase()} />
        <Grid
          gridTemplateColumns="repeat(12, 1fr)"
          gap={'10px'}
          width="100%"
          height="fit-content"
          paddingLeft="24px"
        >
          {children}
        </Grid>
      </Flex>
    </Box>
  );
}

export default Card;
