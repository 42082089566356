import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputHorario from '../../components/Inputs/InputHorario/InputHorario';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import InputTexto from '../../components/Inputs/InputTexto/InputTexto';
import Pagina from '../../components/Pagina/Pagina';
import PesquisaProdutos from '../../components/PesquisaProdutos/PesquisaProdutos';
import TabelaInterna from '../../components/Tabela/TabelaInterna';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { reload } from '../../utils/utils';
import './OP.css';

function EditarOPs() {
  const navigate = useNavigate();
  const [ordemProducao, setOrdemProducao] = useState({
    Codigo: 1,
    Quantidade: 0,
    DepositoOrigem: '',
    DepositoOrigemId: '',
    DepositoDestino: '',
    DepositoDestinoId: '',
    Responsavel: '',
    ResponsavelId: '',
    Status: 1,
    PrevisaoInicio: '2023-03-13',
    PrevisaoEntrega: '2023-03-13',
    Historico: [],
    Observacoes: '',
    StatusSTR: '',
    HorarioInicio: '',
    HorarioTermino: '',
    VendaId: '',
    Produtos: [],
    Id: '',
    CopiaProduto: {
      IsServico: false,
      TipoServico: '',
      Codigo: '',
      CodigoBalanca: '',
      CodigoFornecedor: '',
      CodigoNFe: '',
      Codigo_EAN: '',
      NumeroSerie: '',
      Nome: '',
      Ativo: true,
      Excluido: false,
      Genero: '',
      Unidade: '',
      Categoria: '',
      CategoriaId: '',
      Fornecedor: '',
      FornecedorId: '',
      Marca: '',
      MarcaId: '',
      Tamanho: '',
      TamanhoId: '',
      Prateleira: '',
      PrecoCusto: 0,
      PrecoVenda: 0,
      MVA: 0,
      LucroPercentual: 0,
      FretePercentual: 0,
      SeguroPercentual: 0,
      DespesasPercentual: 0,
      AlteracaoPrecoVenda: '0001-01-01',
      SituacaoTributaria: '',
      SituacaoTributariaId: '',
      Descricao: '',
      EAN_NFe: '',
      EAN_UnidadeTributavel_NFe: '',
      ICMSPercentual: 0,
      IPI_SituacaoTributaria: '',
      IPI: 0,
      IPIPercentual: 0,
      IPI_ClasseEnquadramento: '',
      IPI_EnquadramentoLegal: '',
      NCM_NFe: '',
      NCM_EX_IPI: '',
      CEST_NFe: '',
      CFOPPadrao_NFe: '',
      Comissao: 0,
      ComissaoDinheiro: 0,
      PesoKG: 0,
      PesoLiquido: 0,
      OrigemMercadoria_NFe: 0,
      UnidadeComercial_NFe: '',
      STAliquota: 0,
      SaldoEstoque: 0,
      Quantidade: 0,
      PossuiLotes: false,
      CustoComAcrescimos: 0,
      VariacaoId: '',
      EstoqueMinimo: 0,
      HasImage: false,
      ImageLink: '',
      Largura: 0,
      Profundidade: 0,
      Altura: 0,
      Volumes: 0,
      ItensCaixa: 0,
      Condicao: 'Novo',
      Tipo: 1,
      Grades: [],
      Variacoes: [],
      Composicao: [],
      CSOSN: 0,
      Id: '',
    },
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  useEffect(() => {
    async function GetOPs() {
      let payload = {
        database: localStorage.getItem('database'),
        data: id,
      };
      let res = await api.post('/EditOrdensProducao/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        setOrdemProducao(data.Ordem);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetOPs();
  }, [id]);

  async function save(value) {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditOrdensProducao/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
    reload();
  }

  async function iniciarProducao(value) {
    setLoading(true);
    let payload = {
      data: JSON.stringify(value, null, 2),
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditOrdensProducao/IniciarOrdem', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }

  async function finalizarProducao(value) {
    setLoading(true);
    let payload = {
      data: JSON.stringify(value, null, 2),
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditOrdensProducao/FinalizarOrdem', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  }

  async function gerarDocumento(values) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(values, null, 2),
    };
    let res = await api.post('/EditOrdensProducao/GetDocumento', payload);
    let data = res.data;
    setLoading(false);
    var pdf = data.ItemID;

    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = `OP ${values.Codigo}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={ordemProducao}
          onSubmit={async values => {
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno
            navigate={navigate}
            iniciarProducao={iniciarProducao}
            finalizarProducao={finalizarProducao}
            gerarDocumento={gerarDocumento}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({
  navigate,
  iniciarProducao,
  finalizarProducao,
  gerarDocumento,
}) {
  const formik = useFormikContext();
  let titulo = `${
    formik.values.Id !== '' ? 'Editar' : 'Nova'
  } Ordem de Produção`;

  async function handleAddProduto(nomeProduto) {
    let payload = {
      database: localStorage.getItem('database'),
      data: nomeProduto,
    };
    let res = await api.post('/PDV/GetProdutoTodosTipos', payload);
    if (res.status === 200) {
      let data = res.data;
      addProdutosCarrinho(data.Produtos);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  function addProdutosCarrinho(produto) {
    var achou = false;
    var produtos = formik.values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Produto.Nome === produto.Nome) {
        achou = true;
        produtos[i].Quantidade += 1;
      }
    }

    if (!achou) {
      var pnovo = {};
      pnovo.Produto = produto;
      pnovo.Quantidade = 1;
      produtos.push(pnovo);
    }
    formik.setFieldValue('Produtos', produtos);
  }

  function removeProdutoCarrinho(produto) {
    var produtos = formik.values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Produto.Nome === produto.Produto.Nome) {
        produtos.splice(i, 1);
        break;
      }
    }
    formik.setFieldValue('Produtos', produtos);
  }

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'vermelho',
            onClick: () => navigate('/OrdensProducao/Listar'),
            children: 'Cancelar',
          },
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
            visible: formik.values.Status !== 4 && formik.values.Status !== 5,
          },
          {
            variant: 'amarelo',
            type: 'button',
            children: 'Baixar PDF',
            visible: formik.values.Id !== '',
            onClick: () => gerarDocumento(formik.values),
          },
          {
            variant: 'azul',
            type: 'button',
            children: 'Iniciar Produção',
            visible:
              formik.values.Id !== '' &&
              formik.values.Status !== 2 &&
              formik.values.Status !== 4 &&
              formik.values.Status !== 5,
            onClick: () => iniciarProducao(formik.values),
          },
          {
            variant: 'turquesa',
            type: 'button',
            children: 'Finalizar Ordem',
            visible: formik.values.Id !== '' && formik.values.Status === 2,
            onClick: () => finalizarProducao(formik.values),
          },
        ]}
        caminho={[
          { nome: 'Ordens de Produção', path: '/OrdensProducao/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={titulo}>
        <InputFormik
          titulo="Código"
          id="Codigo"
          name="Codigo"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Status"
          id="Status"
          name="Status"
          component={InputSelect}
          options={[
            { value: '1', name: 'Rascunho' },
            { value: '2', name: 'Em produção' },
            { value: '3', name: 'Pausada' },
            { value: '4', name: 'Cancelada' },
            { value: '5', name: 'Finalizada' },
          ]}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Estoque de Origem"
          id="DepositoOrigem"
          name="DepositoOrigem"
          component={InputAutoComplete}
          autocomplete={true}
          path={'Deposito'}
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Estoque de Destino"
          id="DepositoDestino"
          name="DepositoDestino"
          component={InputAutoComplete}
          autocomplete={true}
          path={'Deposito'}
          database={localStorage.getItem('database')}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Previsão de Entrega"
          id="PrevisaoEntrega"
          name="PrevisaoEntrega"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Previsão de Início"
          id="PrevisaoInicio"
          name="PrevisaoInicio"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Horário de Início"
          id="HorarioInicio"
          name="HorarioInicio"
          component={InputHorario}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Horário de Término"
          id="HorarioTermino"
          name="HorarioTermino"
          component={InputHorario}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Responsável"
          id="Responsavel"
          name="Responsavel"
          component={InputTexto}
          gridColumn={gridColumn.gc4}
        />
        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTextArea}
          gridColumn={gridColumn.gc4}
        />
      </Card>
      <Card titulo="Itens">
        <PesquisaProdutos funcao={handleAddProduto} />
        <TabelaInterna
          nomeLista="Produtos"
          columns={[
            {
              placeholder: 'Produto',
              name: 'Produto.Nome',
              type: 'text',
            },
            {
              placeholder: 'Quantidade',
              name: 'Quantidade',
              type: 'number',
              editavel: true,
            },
          ]}
          showDelete={true}
          handleDelete={removeProdutoCarrinho}
        />
      </Card>
    </>
  );
}

export default EditarOPs;
