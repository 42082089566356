import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioFaturamento } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import FormikPadrao from './../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from './../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';
import Filtros from '../../components/Filtros/Filtros';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import { baixarArquivoBase64 } from '../../utils/download';

function RelFaturamento() {
  const [relFaturamento, setRelFaturamento] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  const [receitasPagas, setReceitasPagas] = useState(0);
  const [receitasPrevistas, setReceitasPrevistas] = useState(0);

  async function GetRelFaturamento(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/FaturamentoReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelFaturamento(data.Lancamentos);
      if (data.Lancamentos) {
        let pagas = 0;
        let previstas = 0;
        data.Lancamentos.map(rel => (pagas += rel.ValorPago));
        data.Lancamentos.map(rel => (previstas += rel.ValorTotal));
        setReceitasPagas(pagas);
        setReceitasPrevistas(previstas);
      } else {
        setReceitasPagas(0);
        setReceitasPrevistas(0);
      }
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelFaturamento(getFiltroPadraoRelatorioFaturamento());
    }
  }, [search]);

  useEffect(() => {
    GetRelFaturamento(getFiltroPadraoRelatorioFaturamento());
  }, []);

  function handleEdit(item) {
    navigate(`/Lancamentos/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relFaturamento, null, 2),
    };
    baixarArquivoBase64('/FaturamentoReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relFaturamento, null, 2),
    };
    baixarArquivoBase64('/FaturamentoReport/GetPDF', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioFaturamento()}
        onSubmit={async values => {
          await GetRelFaturamento(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por lançamentos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'TOTAL DE RECEITAS PAGAS',
              Valor: receitasPagas,
              type: 'money',
            },
            {
              Titulo: 'TOTAL DE RECEITAS PREVISTAS',
              Valor: receitasPrevistas,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE FATURAMENTO FINANCEIRO'}
          setFunction={setRelFaturamento}
          data={relFaturamento}
          columns={[
            {
              placeholder: 'CÓDIGO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'PLANO DE CONTAS',
              name: 'PlanoConta',
              type: 'money',
            },
            {
              placeholder: 'COMPETÊNCIA',
              name: 'DataCompetencia',
              type: 'date',
            },
            {
              placeholder: 'VENCIMENTO',
              name: 'DataVencimento',
              type: 'date',
            },
            {
              placeholder: 'VALOR PREVISTO',
              name: 'ValorTotal',
              type: 'money',
            },
            {
              placeholder: 'VALOR REALIZADO',
              name: 'ValorPago',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  const optionsData = [
    { value: 'vencimento', name: 'Data de Vencimento' },
    { value: 'competencia', name: 'Data de Competência' },
    { value: 'pagamento', name: 'Data de Quitação' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PLANO DE CONTAS"
        id="PlanoConta"
        name="PlanoConta"
        component={InputAutoComplete}
        autocomplete={true}
        path={'PlanosContas'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}
export default RelFaturamento;
