import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Botao from '../../components/Botao/Botao';
import InputSearch from '../Inputs/InputSearch/InputSearch';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { gridColumn } from '../../utils/gridColumn';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import IconeFiltro from '../../components/Icones/IconeFiltro/IconeFiltro';
import './BarraBotoes.css';

function BarraBotoes({
  maxWindowSize = 500,
  botoes,
  search,
  caminho,
  showFilters = null,
  setShowFilters = null,
}) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  botoes = botoes
    .map(botao => {
      if (!botao.type) botao.type = 'button';
      if (botao.visible === undefined || botao.visible === null)
        botao.visible = true;
      if (botao.disabled === undefined || botao.disabled === null)
        botao.disabled = false;
      return botao;
    })
    .filter(botao => botao.visible);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  if (windowSize[0] < maxWindowSize) {
    return (
      <Flex
        p={4}
        gap={4}
        gridColumn={gridColumn.gc12}
        className="searchbuttons"
      >
        <Flex gap={2} flexGrow={1}>
          {search && (
            <InputSearch
              placeholder={search.placeholder}
              style={{ flex: 1 }}
              onChange={search.onChange}
            />
          )}
          {showFilters != null && setShowFilters != null && (
            <Flex alignItems="center" justifyContent="center">
              <Botao
                variant={'filtro'}
                onClick={() => setShowFilters(!showFilters)}
              >
                <IconeFiltro className="iconeFiltro" />
              </Botao>
            </Flex>
          )}
        </Flex>
        <Menu>
          <MenuButton
            variant="outline"
            style={{ border: 'solid 1px grey' }}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            Ações
          </MenuButton>
          <MenuList>
            {botoes.map(botao => (
              <MenuItem
                variant={botao.variant}
                type={botao.type}
                onClick={botao.onClick}
                key={botao.children}
              >
                {botao.children}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    );
  } else if (botoes.length > 5) {
    return (
      <Flex
        p={4}
        gap={4}
        gridColumn={gridColumn.gc12}
        className="searchbuttons"
      >
        <Flex gap={2} flexGrow={1}>
          {search && (
            <InputSearch
              placeholder={search.placeholder}
              style={{ flex: 1 }}
              onChange={search.onChange}
            />
          )}
          {caminho && <Breadcrumbs caminho={caminho} />}

          {showFilters != null && setShowFilters != null && (
            <Flex alignItems="center" justifyContent="center">
              <Botao
                variant={'filtro'}
                onClick={() => setShowFilters(!showFilters)}
              >
                <IconeFiltro className="iconeFiltro" />
              </Botao>
            </Flex>
          )}
        </Flex>
        <Menu>
          <MenuButton
            variant="outline"
            style={{ border: 'solid 1px grey' }}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            Ações
          </MenuButton>
          <MenuList>
            {botoes.map(botao => (
              <MenuItem
                variant={botao.variant}
                type={botao.type}
                onClick={botao.onClick}
                key={botao.children}
              >
                {botao.children}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    );
  }

  return (
    <Flex p={4} gridColumn={gridColumn.gc12} className="searchbuttonsbar">
      <Flex gap={2} flexGrow={1}>
        {search && (
          <InputSearch
            placeholder={search.placeholder}
            style={{ flex: 1 }}
            onChange={search.onChange}
          />
        )}
        {caminho && <Breadcrumbs caminho={caminho} />}
        {showFilters != null && setShowFilters != null && (
          <Flex alignItems="center" justifyContent="center">
            <Botao
              variant={'filtro'}
              onClick={() => setShowFilters(!showFilters)}
            >
              <IconeFiltro className="iconeFiltro" />
            </Botao>
          </Flex>
        )}
      </Flex>
      <Box className="buttonsBar">
        {botoes.map(botao => (
          <Botao
            variant={botao.variant}
            type={botao.type}
            onClick={botao.onClick}
            key={botao.children}
          >
            {botao.children}
          </Botao>
        ))}
      </Box>
    </Flex>
  );
}

export default BarraBotoes;
