import { Input } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import './InputDinheiro.css';

function InputDinheiro({ placeholder, onValueChange, onChange, decimalScale = 2, ...rest }) {
  const { setFieldValue } = useFormikContext();

  return (
    <CurrencyInput
      disableAbbreviations
      decimalSeparator=","
      groupSeparator='.'
      prefix={'R$ '}
      placeholder="R$ "
      className="inputdinheiro"
      customInput={Input}
      decimalScale={decimalScale}
      decimalsLimit={decimalScale}
      onValueChange={value => {
        setFieldValue(rest.name, value);
      }}
      {...rest}
    />
  );
}

export default InputDinheiro;
