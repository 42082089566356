import React from 'react';
import { RiFilterFill } from 'react-icons/ri';
import './IconeFiltro.css';

function IconeFiltro({ ...rest }) {
  return (
    <RiFilterFill className="iconeFiltro" {...rest} />
  );
}

export default IconeFiltro;
