import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioFluxoCaixa } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import { ajustaDatas } from '../../utils/utils';
import InputNumInt from './../../components/Inputs/InputNumInt/InputNumInt';
import SecaoCadastro from '../../components/SecaoCadastro/SecaoCadastro';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { formataDinheiro } from '../../utils/formatador';

function RelFluxoCaixa() {
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);
  const [relFluxoCaixa, setRelFluxoCaixa] = useState({
    Ano: new Date().getFullYear(),
    Meses: [],
  });

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  async function GetRelFluxoCaixa(filtros) {
    setLoading(true);
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/FluxoCaixaMensalReport/GetList', payload);
    let data = res.data;
    console.log(data);
    if (res.status === 200) {
      setRelFluxoCaixa(data.Fluxo);
      console.log(data.Fluxo);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelFluxoCaixa(getFiltroPadraoRelatorioFluxoCaixa());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelFluxoCaixa(getFiltroPadraoRelatorioFluxoCaixa());
    }
  }, [search]);


  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioFluxoCaixa()}
        onSubmit={async values => {
          await GetRelFluxoCaixa(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            // {
            //   children: 'Exportar PDF',
            //   onClick: getPDF,
            //   variant: 'rosa',
            // },
          ]}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <Box mb={7} gridColumn={gridColumn.gc12} className="card">
          <SecaoCadastro titulo={'RELATÓRIO DE FLUXO DE CAIXA'} />
          {relFluxoCaixa.Meses.length > 0 &&
            relFluxoCaixa.Meses.map((mes, i) => (
              <Table>
                <Thead>
                  <Tr>
                    <Th colspan="4">
                      {meses[i]} {relFluxoCaixa.Ano}
                    </Th>
                  </Tr>
                  <Tr>
                    <Th colspan="2">Receitas</Th>
                    <Th colspan="2">Despesas</Th>
                  </Tr>
                  <Tr>
                    <Th scope="col">Previsto</Th>
                    <Th scope="col">Realizado</Th>
                    <Th scope="col">Previsto</Th>
                    <Th scope="col">Realizado</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{formataDinheiro(mes.ReceitasPrevistas)}</Td>
                    <Td>{formataDinheiro(mes.ReceitasRealizadas)}</Td>
                    <Td>{formataDinheiro(mes.DespesasPrevistas)}</Td>
                    <Td>{formataDinheiro(mes.DespesasRealizadas)}</Td>
                  </Tr>
                </Tbody>
              </Table>
            ))}
        </Box>
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc6}
      />
      <InputFiltro
        titulo="Ano"
        id="Ano"
        name="Ano"
        component={InputNumInt}
        gridColumn={gridColumn.gc6}
      />
    </Filtros>
  );
}
export default RelFluxoCaixa;
