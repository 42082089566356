const valor_inicial = {
  NomeFantasia: '',
  RazaoSocial: '',
  CNPJ: '',
  Matriz: '',
  IE: '',
  IM: '',
  CNAE: '',
  Padrao: false,
  CEP: '',
  Logradouro: '',
  LogradouroNumero: '',
  LogradouroComplemento: '',
  Bairro: '',
  Cidade: '',
  UF: '',
  CodigoMunicipio: 0,
  CodigoUF: 0,
  CodigoPais: 0,
  Pais: '',
  RegimeApuracao: 'SimplesNacional',
  AtividadePreponderante: 'IndustriaEquiparadoIndustrial',
  IncidenciaTributaria: '',
  Email: '',
  Site: '',
  Telefone: '',
  Celular: '',
  MatrizID: '',
  Deposito: '',
  DepositoId: '',
  Banco: '',
  BancoId: '',
  JurosPadrao: 0,
  MultaPadrao: 0,
  TotalCustoFixo: 0,
  FaturamentoEstimado: 0,
  PorcentagemFaturamento: 0,
  AutomatizarEstoque: false,
  HasImage: false,
  ImageLink: '',
  Certificado: '',
  NomeCertificado: '',
  ExpiracaoCertificado: '',
  ConfiguracaoFiscal: {
    SenhaCertificado: '',
    TipoAmbiente: 2,
    ModeloDocumento: 0,
    Emitente: '',
    ConfiguracaoCSC: {
      CIdToken: '',
      Csc: '',
    },
    ConfiguracaoEmail: {
      Email: '',
      Senha: '',
      Assunto: '',
      Mensagem: '',
      ServidorSmtp: '',
      Porta: 0,
      Ssl: false,
      MensagemEmHtml: false,
      Timeout: 0,
      Assincrono: false,
      Nome: '',
    },
  },
  SerieNFe: 0,
  NumeroNFe: 0,
  ChaveIntegracaoDominioContabil: '',
  InteresseContribuinte: '',
  InteresseFisco: '',
  CFOP_Padrao: '',
  MostrarNomeFantasiaDanfe: false,
  ArquivosRH: [],
  Id: '',
};

const optionsTipoAmbiente = [
  { value: '1', name: '1 - Produção' },
  { value: '2', name: '2 - Homologação' },
];

const ufs = [
  { value: 12, name: 'AC', nome: 'Acre' },
  { value: 27, name: 'AL', nome: 'Alagoas' },
  { value: 16, name: 'AP', nome: 'Amapá' },
  { value: 13, name: 'AM', nome: 'Amazonas' },
  { value: 29, name: 'BA', nome: 'Bahia' },
  { value: 23, name: 'CE', nome: 'Ceará' },
  { value: 53, name: 'DF', nome: 'Distrito Federal' },
  { value: 32, name: 'ES', nome: 'Espírito Santo' },
  { value: 52, name: 'GO', nome: 'Goiás' },
  { value: 21, name: 'MA', nome: 'Maranhão' },
  { value: 51, name: 'MT', nome: 'Mato Grosso' },
  { value: 50, name: 'MS', nome: 'Mato Grosso do Sul' },
  { value: 31, name: 'MG', nome: 'Minas Gerais' },
  { value: 15, name: 'PA', nome: 'Pará' },
  { value: 25, name: 'PB', nome: 'Paraíba' },
  { value: 41, name: 'PR', nome: 'Paraná' },
  { value: 26, name: 'PE', nome: 'Pernambuco' },
  { value: 22, name: 'PI', nome: 'Piauí' },
  { value: 33, name: 'RJ', nome: 'Rio de Janeiro' },
  { value: 24, name: 'RN', nome: 'Rio Grande do Norte' },
  { value: 43, name: 'RS', nome: 'Rio Grande do Sul' },
  { value: 11, name: 'RO', nome: 'Rondônia' },
  { value: 14, name: 'RR', nome: 'Roraima' },
  { value: 42, name: 'SC', nome: 'Santa Catarina' },
  { value: 35, name: 'SP', nome: 'São Paulo' },
  { value: 28, name: 'SE', nome: 'Sergipe' },
  { value: 17, name: 'TO', nome: 'Tocantins' },
];

export { ufs, optionsTipoAmbiente, valor_inicial };
