import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioOrdensServico } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Tabela from '../../components/Tabela/Tabela';

function RelOS() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relOS, setRelOS] = useState([]);

  async function GetRelOS(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/OrdensServicoReport/GetList', payload);
    let data = res.data;

    setRelOS(data.Ordens);
    setLoading(false);
  }

  useEffect(() => {
    GetRelOS(getFiltroPadraoRelatorioOrdensServico());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelOS(getFiltroPadraoRelatorioOrdensServico());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/OrdensServico/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relOS, null, 2),
    };
    baixarArquivoBase64('/OrdensServicoReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relOS, null, 2),
    };
    baixarArquivoBase64('/OrdensServicoReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioOrdensServico()}
        onSubmit={async values => {
          await GetRelOS(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por ordens',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <Tabela
          titulo={'RELATÓRIO DE ORDENS DE SERVIÇO'}
          setFunction={setRelOS}
          data={relOS}
          columns={[
            {
              placeholder: 'ORDEM',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'DATA DE INÍCIO',
              name: 'DataInicio',
              type: 'date',
            },
            {
              placeholder: 'PREVISÃO DE ENTREGA',
              name: 'PrevisaoEntrega',
              type: 'date',
            },
            {
              placeholder: 'STATUS',
              name: 'Status',
              type: 'text',
            },
            {
              placeholder: 'VALOR',
              name: 'Valor',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();
  const optionsData = [
    { value: 'Inicio', name: 'Data de Início' },
    { value: 'Entrega', name: 'Previsão de Entrega' },
  ];

  const optionsStatus = [
    { value: '', name: 'Status' },
    { value: 'Pendente', name: 'Pendente' },
    { value: 'Em Andamento', name: 'Em Andamento' },
    { value: 'Concluido', name: 'Concluído' },
    { value: 'Entregue', name: 'Entregue' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="SERVIÇO"
        id="Servico"
        name="Servico"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Servicos'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="CLIENTE"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Cliente'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="SITUAÇÃO"
        id="Situacao"
        name="Situacao"
        component={InputSelect}
        options={optionsStatus}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelOS;
