import React from 'react';
import { RiAddCircleFill } from 'react-icons/ri';
import './IconeAdicionar.css';

function IconeAdicionar () {
  return (
    <RiAddCircleFill className="IconeAdicionar" />
  );
}

export default IconeAdicionar;
