import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import './ConfigPDV.css';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import Card from '../../components/Card/Card';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';

function EditarConfigPDV() {
  const [configPDV, setConfigPDV] = useState({
    Empresa: '',
    Deposito: '',
    TabelaPreco: '',
    PlanoConta: '',
    Vendedor: '',
    PermiteEmissaoNFCe: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');
  const { email } = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    async function GetConfigPDV() {
      let payload = {
        database: localStorage.getItem('database'),
        arg: email,
      };
      let res = await api.post('/PDV/GetDadosCaixa', payload);
      if (res.status === 200) {
        let data = res.data;
        setConfigPDV(data.caixa);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetConfigPDV();
  }, [email]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/PDV/SaveCaixa', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          enableReinitialize={true}
          initialValues={configPDV}
          onSubmit={async values => {
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno() {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('EmpresaId', '');
    setFieldValue('DepositoId', '');
    setFieldValue('TabelaPrecoId', '');
    setFieldValue('PlanoContaId', '');
    setFieldValue('VendedorId', '');
  }, [values, setFieldValue]);

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
          },
        ]}
      />
      <Card titulo="CONFIGURAÇÕES DO PDV">
        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Estoque"
          id="Deposito"
          name="Deposito"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Deposito"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Tabela de Preços"
          id="TabelaPreco"
          name="TabelaPreco"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="TabelasPreco"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Plano de Contas"
          id="PlanoConta"
          name="PlanoConta"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="PlanosContasReceita"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Vendedor"
          id="Vendedor"
          name="Vendedor"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Vendedor"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        <InputFormik
          titulo="Permite emissão de NFCe?"
          id="PermiteEmissaoNFCe"
          name="PermiteEmissaoNFCe"
          component={InputToggle}
          gridColumn={gridColumn.gc4}
        />
      </Card>
    </>
  );
}

export default EditarConfigPDV;
