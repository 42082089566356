import React, { useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody, AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader, AlertDialogOverlay,
  Button,
  Input
} from '@chakra-ui/react';

function AlertaCampo({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
}) {
  const cancelRef = React.useRef();
  const [value, setValue] = useState(0);
  const handleChange = (event) => setValue(event.target.value)
  return (
    isOpen && (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {description}
              <Input type="number" value={value} onChange={handleChange} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={() => onConfirm(value)} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )
  );
}

export default AlertaCampo;