import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioPrevisaoCaixa } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Filtros from '../../components/Filtros/Filtros';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import { useFormikContext } from 'formik';

function RelPrevisaoCaixa() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [relPrevisaoCaixa, setRelPrevisaoCaixa] = useState([]);
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalRecebido, setTotalRecebido] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const [totalPago, setTotalPago] = useState(0);

  async function GetRelPrevisaoCaixa(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/PrevisaoCaixaReport/GetList', payload);
    if (res.status === 200) {
      let data = res.data;
      let receber = 0;
      let recebido = 0;
      let pagar = 0;
      let pago = 0;
      setRelPrevisaoCaixa(data.Lancamentos);
      data.Lancamentos.map(rel => (receber += rel.ValorTotal));
      data.Lancamentos.map(rel => (recebido += rel.ValorPago));
      data.Lancamentos.map(rel => (pagar += rel.ValorTotal));
      data.Lancamentos.map(rel => (pago += rel.ValorPago));
      setTotalReceber(receber);
      setTotalRecebido(recebido);
      setTotalPagar(pagar);
      setTotalPago(pago);
    }
    setLoading(false);
  }

  useEffect(() => {
    GetRelPrevisaoCaixa(getFiltroPadraoRelatorioPrevisaoCaixa());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelPrevisaoCaixa(getFiltroPadraoRelatorioPrevisaoCaixa());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/Lancamentos/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relPrevisaoCaixa, null, 2),
    };
    baixarArquivoBase64('/PrevisaoCaixaReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relPrevisaoCaixa, null, 2),
    };
    baixarArquivoBase64('/PrevisaoCaixaReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioPrevisaoCaixa()}
        onSubmit={async values => {
          await GetRelPrevisaoCaixa(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por lançamentos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'TOTAL A RECEBER',
              Valor: totalReceber,
              type: 'money',
            },
            {
              Titulo: 'TOTAL RECEBIDO',
              Valor: totalRecebido,
              type: 'money',
            },
            {
              Titulo: 'TOTAL A PAGAR',
              Valor: totalPagar,
              type: 'money',
            },
            {
              Titulo: 'TOTAL PAGO',
              Valor: totalPago,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE PREVISÃO DE CAIXA'}
          setFunction={setRelPrevisaoCaixa}
          data={relPrevisaoCaixa}
          columns={[
            {
              placeholder: 'CÓDIGO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
            {
              placeholder: 'TIPO',
              name: 'Tipo',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'FORMA DE PAGAMENTO',
              name: 'FormaPagamento',
              type: 'text',
            },
            {
              placeholder: 'COMPETÊNCIA',
              name: 'DataCompetencia',
              type: 'date',
            },
            {
              placeholder: 'VENCIMENTO',
              name: 'DataVencimento',
              type: 'date',
            },
            {
              placeholder: 'CENTRO DE CUSTOS',
              name: 'CentroCustos',
              type: 'text',
            },
            {
              placeholder: 'PLANO DE CONTAS',
              name: 'PlanoConta',
              type: 'money',
            },
            {
              placeholder: 'VALOR PREVISTO',
              name: 'ValorTotal',
              type: 'money',
            },
            {
              placeholder: 'VALOR REALIZADO',
              name: 'ValorPago',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  const optionsData = [
    { value: 'vencimento', name: 'Filtrar por Data de Vencimento' },
    { value: 'competencia', name: 'Filtrar Por Data de Competência' },
  ];

  const optionsPagamento = [
    { value: '', name: 'Forma de Pagamento' },
    { value: 'Dinheiro', name: 'Dinheiro' },
    { value: 'Carne', name: 'Carnê' },
    { value: 'CartaoDebito', name: 'Cartão de Débito' },
    { value: 'CartaoCredito', name: 'Cartão de Crédito' },
    { value: 'Cheque', name: 'Cheque' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PLANO DE CONTAS"
        id="PlanoConta"
        name="PlanoConta"
        component={InputAutoComplete}
        autocomplete={true}
        path={'PlanosContas'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="CENTRO DE CUSTOS"
        id="CentroCustos"
        name="CentroCustos"
        component={InputAutoComplete}
        autocomplete={true}
        path={'CentroCustos'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FORMA DE PAGAMENTO"
        id="FormaPagamento"
        name="FormaPagamento"
        component={InputSelect}
        options={optionsPagamento}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelPrevisaoCaixa;
