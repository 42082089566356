import React from 'react';
import Logo from '../../images/logo-erp.png';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loaderInteiro">
      <img src={Logo} alt="Logo" className="logo" />
      <div
        role="alert"
        className="loader"
      >
      </div>
    </div>
  );
}

export default Loader;