import { orderBy } from 'natural-orderby';

const estados = [
  { value: 'AC', name: 'AC' },
  { value: 'AL', name: 'AL' },
  { value: 'AP', name: 'AP' },
  { value: 'AM', name: 'AM' },
  { value: 'BA', name: 'BA' },
  { value: 'CE', name: 'CE' },
  { value: 'DF', name: 'DF' },
  { value: 'ES', name: 'ES' },
  { value: 'GO', name: 'GO' },
  { value: 'MA', name: 'MA' },
  { value: 'MT', name: 'MT' },
  { value: 'MS', name: 'MS' },
  { value: 'MG', name: 'MG' },
  { value: 'PA', name: 'PA' },
  { value: 'PB', name: 'PB' },
  { value: 'PE', name: 'PE' },
  { value: 'PR', name: 'PR' },
  { value: 'PI', name: 'PI' },
  { value: 'RJ', name: 'RJ' },
  { value: 'RN', name: 'RN' },
  { value: 'RO', name: 'RO' },
  { value: 'RR', name: 'RR' },
  { value: 'RS', name: 'RS' },
  { value: 'SC', name: 'SC' },
  { value: 'SE', name: 'SE' },
  { value: 'SP', name: 'SP' },
  { value: 'TO', name: 'TO' },
];

const optionsPaises = [
  {
    value: '0132',
    name: 'Afeganistão',
  },
  {
    value: '7560',
    name: 'África do Sul',
  },
  {
    value: '0175',
    name: 'Albânia, República da',
  },
  {
    value: '0230',
    name: 'Alemanha',
  },
  {
    value: '0370',
    name: 'Andorra',
  },
  {
    value: '0400',
    name: 'Angola',
  },
  {
    value: '0418',
    name: 'Antígua e Barbuda',
  },
  {
    value: '0434',
    name: 'Antilhas Holandesas',
  },
  {
    value: '0531',
    name: 'Arábia Saudita',
  },
  {
    value: '0590',
    name: 'Argélia',
  },
  {
    value: '0639',
    name: 'Argentina',
  },
  {
    value: '0647',
    name: 'Armênia, República da',
  },
  {
    value: '0655',
    name: 'Aruba',
  },
  {
    value: '0698',
    name: 'Austrália',
  },
  {
    value: '0728',
    name: 'Áustria',
  },
  {
    value: '0736',
    name: 'Azerbaijão, República do',
  },
  {
    value: '0779',
    name: 'Bahamas, Ilhas',
  },
  {
    value: '0809',
    name: 'Bahrein, Ilhas',
  },
  {
    value: '0817',
    name: 'Bangladesh',
  },
  {
    value: '0833',
    name: 'Barbados',
  },
  {
    value: '0850',
    name: 'Belarus',
  },
  {
    value: '0876',
    name: 'Bélgica',
  },
  {
    value: '0884',
    name: 'Belize',
  },
  {
    value: '2291',
    name: 'Benin',
  },
  {
    value: '0906',
    name: 'Bermudas',
  },
  {
    value: '0973',
    name: 'Bolívia',
  },
  {
    value: '0981',
    name: 'Bósnia-Herzegovina',
  },
  {
    value: '1015',
    name: 'Botsuana',
  },
  {
    value: '1058',
    name: 'Brasil',
  },
  {
    value: '1082',
    name: 'Brunei',
  },
  {
    value: '1112',
    name: 'Bulgária, República da',
  },
  {
    value: '0310',
    name: 'Burkina Faso',
  },
  {
    value: '1155',
    name: 'Burundi',
  },
  {
    value: '1198',
    name: 'Butão',
  },
  {
    value: '1279',
    name: 'Cabo Verde, República de',
  },
  {
    value: '1457',
    name: 'Camarões',
  },
  {
    value: '1414',
    name: 'Camboja',
  },
  {
    value: '1490',
    name: 'Canadá',
  },
  {
    value: '1504',
    name: 'Canal, Ilhas do (Jersey e Guernsey)',
  },
  {
    value: '1511',
    name: 'Canárias, Ilhas',
  },
  {
    value: '1546',
    name: 'Catar',
  },
  {
    value: '1376',
    name: 'Cayman, Ilhas',
  },
  {
    value: '1538',
    name: 'Cazaquistão, República do',
  },
  {
    value: '7889',
    name: 'Chade',
  },
  {
    value: '1589',
    name: 'Chile',
  },
  {
    value: '1600',
    name: 'China, República Popular da',
  },
  {
    value: '1635',
    name: 'Chipre',
  },
  {
    value: '5118',
    name: 'Christmas, Ilha (Navidad)',
  },
  {
    value: '7412',
    name: 'Cingapura',
  },
  {
    value: '1651',
    name: 'Cocos (Keeling), Ilhas',
  },
  {
    value: '1694',
    name: 'Colômbia',
  },
  {
    value: '1732',
    name: 'Comores, Ilhas',
  },
  {
    value: '8885',
    name: 'Congo, República Democrática do',
  },
  {
    value: '1775',
    name: 'Congo, República do',
  },
  {
    value: '1830',
    name: 'Cook, Ilhas',
  },
  {
    value: '1872',
    name: 'Coréia, Rep. Pop. Democrática da',
  },
  {
    value: '1902',
    name: 'Coréia, República da',
  },
  {
    value: '1937',
    name: 'Costa do Marfim',
  },
  {
    value: '1961',
    name: 'Costa Rica',
  },
  {
    value: '1988',
    name: 'Coveite',
  },
  {
    value: '1953',
    name: 'Croácia, República da',
  },
  {
    value: '1996',
    name: 'Cuba',
  },
  {
    value: '2321',
    name: 'Dinamarca',
  },
  {
    value: '7838',
    name: 'Djibuti',
  },
  {
    value: '2356',
    name: 'Dominica, Ilha',
  },
  {
    value: '2402',
    name: 'Egito',
  },
  {
    value: '6874',
    name: 'El Salvador',
  },
  {
    value: '2445',
    name: 'Emirados Árabes Unidos',
  },
  {
    value: '2399',
    name: 'Equador',
  },
  {
    value: '2437',
    name: 'Eritreia',
  },
  {
    value: '6289',
    name: 'Escócia',
  },
  {
    value: '2470',
    name: 'Eslováquia, República',
  },
  {
    value: '2461',
    name: 'Eslovênia, República da',
  },
  {
    value: '2453',
    name: 'Espanha',
  },
  {
    value: '2496',
    name: 'Estados Unidos',
  },
  {
    value: '2518',
    name: 'Estônia, República da',
  },
  {
    value: '2534',
    name: 'Etiópia',
  },
  {
    value: '2550',
    name: 'Falkland (Ilhas Malvinas)',
  },
  {
    value: '2593',
    name: 'Feroe, Ilhas',
  },
  {
    value: '8702',
    name: 'Fiji',
  },
  {
    value: '2674',
    name: 'Filipinas',
  },
  {
    value: '2712',
    name: 'Finlândia',
  },
  {
    value: '1619',
    name: 'Formosa (Taiwan)',
  },
  {
    value: '2755',
    name: 'França',
  },
  {
    value: '2810',
    name: 'Gabão',
  },
  {
    value: '6289',
    name: 'Gales, País de',
  },
  {
    value: '2852',
    name: 'Gâmbia',
  },
  {
    value: '2895',
    name: 'Gana',
  },
  {
    value: '2917',
    name: 'Geórgia, República da',
  },
  {
    value: '2933',
    name: 'Gibraltar',
  },
  {
    value: '6289',
    name: 'Grã-Bretanha',
  },
  {
    value: '2976',
    name: 'Granada',
  },
  {
    value: '3018',
    name: 'Grécia',
  },
  {
    value: '3050',
    name: 'Groenlândia',
  },
  {
    value: '3093',
    name: 'Guadalupe',
  },
  {
    value: '3131',
    name: 'Guam',
  },
  {
    value: '3174',
    name: 'Guatemala',
  },
  {
    value: '3379',
    name: 'Guiana',
  },
  {
    value: '3255',
    name: 'Guiana Francesa',
  },
  {
    value: '3298',
    name: 'Guiné',
  },
  {
    value: '3344',
    name: 'Guiné-Bissau',
  },
  {
    value: '3310',
    name: 'Guiné-Equatorial',
  },
  {
    value: '3417',
    name: 'Haiti',
  },
  {
    value: '5738',
    name: 'Holanda (Países Baixos)',
  },
  {
    value: '3450',
    name: 'Honduras',
  },
  {
    value: '3514',
    name: 'Hong Kong, Região Adm. Especial',
  },
  {
    value: '3557',
    name: 'Hungria, República da',
  },
  {
    value: '3573',
    name: 'Iêmen',
  },
  {
    value: '3611',
    name: 'Índia',
  },
  {
    value: '3654',
    name: 'Indonésia',
  },
  {
    value: '6289',
    name: 'Inglaterra',
  },
  {
    value: '3727',
    name: 'Irã, República Islâmica do',
  },
  {
    value: '3697',
    name: 'Iraque',
  },
  {
    value: '3751',
    name: 'Irlanda',
  },
  {
    value: '6289',
    name: 'Irlanda do Norte',
  },
  {
    value: '3794',
    name: 'Islândia',
  },
  {
    value: '3832',
    name: 'Israel',
  },
  {
    value: '3867',
    name: 'Itália',
  },
  {
    value: '3883',
    name: 'Iugoslávia, República Fed. da',
  },
  {
    value: '3913',
    name: 'Jamaica',
  },
  {
    value: '3999',
    name: 'Japão',
  },
  {
    value: '3964',
    name: 'Johnston, Ilhas',
  },
  {
    value: '4030',
    name: 'Jordânia',
  },
  {
    value: '4111',
    name: 'Kiribati',
  },
  {
    value: '4200',
    name: 'Laos, Rep. Pop. Democrática do',
  },
  {
    value: '4235',
    name: 'Lebuan',
  },
  {
    value: '4260',
    name: 'Lesoto',
  },
  {
    value: '4278',
    name: 'Letônia, República da',
  },
  {
    value: '4316',
    name: 'Líbano',
  },
  {
    value: '4340',
    name: 'Libéria',
  },
  {
    value: '4383',
    name: 'Líbia',
  },
  {
    value: '4405',
    name: 'Liechtenstein',
  },
  {
    value: '4421',
    name: 'Lituânia, República da',
  },
  {
    value: '4456',
    name: 'Luxemburgo',
  },
  {
    value: '4472',
    name: 'Macau',
  },
  {
    value: '4499',
    name: 'Macedônia',
  },
  {
    value: '4502',
    name: 'Madagascar',
  },
  {
    value: '4525',
    name: 'Madeira, Ilha da',
  },
  {
    value: '4553',
    name: 'Malásia',
  },
  {
    value: '4588',
    name: 'Malawi',
  },
  {
    value: '4618',
    name: 'Maldivas',
  },
  {
    value: '4642',
    name: 'Mali',
  },
  {
    value: '4677',
    name: 'Malta',
  },
  {
    value: '3595',
    name: 'Man, Ilhas',
  },
  {
    value: '4723',
    name: 'Northern Mariana Islands',
  },
  {
    value: '4740',
    name: 'Morocco',
  },
  {
    value: '4766',
    name: 'Marshall Islands',
  },
  {
    value: '4774',
    name: 'Martinique',
  },
  {
    value: '4855',
    name: 'Mauritius',
  },
  {
    value: '4880',
    name: 'Mauritania',
  },
  {
    value: '4936',
    name: 'Mexico',
  },
  {
    value: '0930',
    name: 'Myanmar (Burma)',
  },
  {
    value: '4995',
    name: 'Micronesia',
  },
  {
    value: '4901',
    name: 'Midway Islands',
  },
  {
    value: '5053',
    name: 'Mozambique',
  },
  {
    value: '4944',
    name: 'Moldova, Republic of',
  },
  {
    value: '4952',
    name: 'Monaco',
  },
  {
    value: '4979',
    name: 'Mongolia',
  },
  {
    value: '5010',
    name: 'Montserrat, Ilhas',
  },
  {
    value: '5070',
    name: 'Namibia',
  },
  {
    value: '5088',
    name: 'Nauru',
  },
  {
    value: '5177',
    name: 'Nepal',
  },
  {
    value: '5215',
    name: 'Nicaragua',
  },
  {
    value: '5258',
    name: 'Niger',
  },
  {
    value: '5282',
    name: 'Nigeria',
  },
  {
    value: '5312',
    name: 'Niue, Ilha',
  },
  {
    value: '5355',
    name: 'Norfolk, Ilha',
  },
  {
    value: '5380',
    name: 'Noruega',
  },
  {
    value: '5428',
    name: 'Nova Caledônia',
  },
  {
    value: '5487',
    name: 'Nova Zelândia',
  },
  {
    value: '5568',
    name: 'Oman',
  },
  {
    value: '5738',
    name: 'Países Baixos (Holanda)',
  },
  {
    value: '5754',
    name: 'Palau',
  },
  {
    value: '5800',
    name: 'Panamá',
  },
  {
    value: '5452',
    name: 'Papua Nova Guiné',
  },
  {
    value: '5762',
    name: 'Paquistão',
  },
  {
    value: '5860',
    name: 'Paraguai',
  },
  {
    value: '5894',
    name: 'Peru',
  },
  {
    value: '5932',
    name: 'Pitcairn, Ilha',
  },
  {
    value: '5991',
    name: 'Polinésia Francesa',
  },
  {
    value: '6033',
    name: 'Polônia, República da',
  },
  {
    value: '6114',
    name: 'Porto Rico',
  },
  {
    value: '6076',
    name: 'Portugal',
  },
  {
    value: '6238',
    name: 'Quênia',
  },
  {
    value: '6254',
    name: 'Quirguiz, República',
  },
  {
    value: '6289',
    name: 'Reino Unido',
  },
  {
    value: '6408',
    name: 'República Centro-Africana',
  },
  {
    value: '6475',
    name: 'República Dominicana',
  },
  {
    value: '6602',
    name: 'Reunião, Ilha',
  },
  {
    value: '6700',
    name: 'Romênia',
  },
  {
    value: '6750',
    name: 'Ruanda',
  },
  {
    value: '6769',
    name: 'Rússia',
  },
  {
    value: '6858',
    name: 'Saara Ocidental',
  },
  {
    value: '6777',
    name: 'Salomão, Ilhas',
  },
  {
    value: '6904',
    name: 'Samoa',
  },
  {
    value: '6912',
    name: 'Samoa Americana',
  },
  {
    value: '6971',
    name: 'San Marino',
  },
  {
    value: '7102',
    name: 'Santa Helena',
  },
  {
    value: '7153',
    name: 'Santa Lúcia',
  },
  {
    value: '6955',
    name: 'São Cristóvão e Neves',
  },
  {
    value: '7005',
    name: 'São Pedro e Miquelon',
  },
  {
    value: '7200',
    name: 'São Tomé e Príncipe, Ilhas',
  },
  {
    value: '7056',
    name: 'São Vicente e Granadinas',
  },
  {
    value: '7285',
    name: 'Senegal',
  },
  {
    value: '7358',
    name: 'Serra Leoa',
  },
  {
    value: '7315',
    name: 'Seychelles',
  },
  {
    value: '7447',
    name: 'Síria, República Árabe da',
  },
  {
    value: '7480',
    name: 'Somália',
  },
  {
    value: '7501',
    name: 'Sri Lanka',
  },
  {
    value: '7544',
    name: 'Suazilândia',
  },
  {
    value: '7595',
    name: 'Sudão',
  },
  {
    value: '7641',
    name: 'Suécia',
  },
  {
    value: '7676',
    name: 'Suíça',
  },
  {
    value: '7706',
    name: 'Suriname',
  },
  {
    value: '7722',
    name: 'Tadjiquistão',
  },
  {
    value: '7765',
    name: 'Tailândia',
  },
  {
    value: '7803',
    name: 'Tanzânia, República Unida da',
  },
  {
    value: '7919',
    name: 'Tcheca, República',
  },
  {
    value: '7820',
    name: 'Território Britânico Oc. Índico',
  },
  {
    value: '7951',
    name: 'Timor Leste',
  },
  {
    value: '8001',
    name: 'Togo',
  },
  {
    value: '8109',
    name: 'Tonga',
  },
  {
    value: '8052',
    name: 'Toquelau, Ilhas',
  },
  {
    value: '8150',
    name: 'Trinidad e Tobago',
  },
  {
    value: '8206',
    name: 'Tunísia',
  },
  {
    value: '8230',
    name: 'Turcas e Caicos, Ilhas',
  },
  {
    value: '8249',
    name: 'Turcomenistão, República do',
  },
  {
    value: '8273',
    name: 'Turquia',
  },
  {
    value: '8281',
    name: 'Tuvalu',
  },
  {
    value: '8311',
    name: 'Ucrânia',
  },
  {
    value: '8338',
    name: 'Uganda',
  },
  {
    value: '8451',
    name: 'Uruguai',
  },
  {
    value: '8478',
    name: 'Uzbequistão, República do',
  },
  {
    value: '5517',
    name: 'Vanuatu',
  },
  {
    value: '8486',
    name: 'Vaticano, Estado da Cidade do',
  },
  {
    value: '8508',
    name: 'Venezuela',
  },
  {
    value: '8583',
    name: 'Vietnã',
  },
  {
    value: '8630',
    name: 'Virgens, Ilhas (Britânicas)',
  },
  {
    value: '8664',
    name: 'Virgens, Ilhas (E.U.A.)',
  },
  {
    value: '8737',
    name: 'Wake, Ilha',
  },
  {
    value: '8753',
    name: 'Wallis e Futuna, Ilhas',
  },
  {
    value: '8907',
    name: 'Zâmbia',
  },
  {
    value: '6653',
    name: 'Zimbábue',
  },
  {
    value: '8958',
    name: 'Zona do Canal do Panamá',
  },
];

const optionsUFs = [
  { value: 12, name: 'AC', nome: 'Acre' },
  { value: 27, name: 'AL', nome: 'Alagoas' },
  { value: 16, name: 'AP', nome: 'Amapá' },
  { value: 13, name: 'AM', nome: 'Amazonas' },
  { value: 29, name: 'BA', nome: 'Bahia' },
  { value: 23, name: 'CE', nome: 'Ceará' },
  { value: 53, name: 'DF', nome: 'Distrito Federal' },
  { value: 32, name: 'ES', nome: 'Espírito Santo' },
  { value: 52, name: 'GO', nome: 'Goiás' },
  { value: 21, name: 'MA', nome: 'Maranhão' },
  { value: 51, name: 'MT', nome: 'Mato Grosso' },
  { value: 50, name: 'MS', nome: 'Mato Grosso do Sul' },
  { value: 31, name: 'MG', nome: 'Minas Gerais' },
  { value: 15, name: 'PA', nome: 'Pará' },
  { value: 25, name: 'PB', nome: 'Paraíba' },
  { value: 41, name: 'PR', nome: 'Paraná' },
  { value: 26, name: 'PE', nome: 'Pernambuco' },
  { value: 22, name: 'PI', nome: 'Piauí' },
  { value: 33, name: 'RJ', nome: 'Rio de Janeiro' },
  { value: 24, name: 'RN', nome: 'Rio Grande do Norte' },
  { value: 43, name: 'RS', nome: 'Rio Grande do Sul' },
  { value: 11, name: 'RO', nome: 'Rondônia' },
  { value: 14, name: 'RR', nome: 'Roraima' },
  { value: 42, name: 'SC', nome: 'Santa Catarina' },
  { value: 35, name: 'SP', nome: 'São Paulo' },
  { value: 28, name: 'SE', nome: 'Sergipe' },
  { value: 17, name: 'TO', nome: 'Tocantins' },
];

const optionsUFsExportacao = [
  { value: 'AC', name: 'AC', nome: 'Acre' },
  { value: 'AL', name: 'AL', nome: 'Alagoas' },
  { value: 'AP', name: 'AP', nome: 'Amapá' },
  { value: 'AM', name: 'AM', nome: 'Amazonas' },
  { value: 'BA', name: 'BA', nome: 'Bahia' },
  { value: 'CE', name: 'CE', nome: 'Ceará' },
  { value: 'DF', name: 'DF', nome: 'Distrito Federal' },
  { value: 'ES', name: 'ES', nome: 'Espírito Santo' },
  { value: 'GO', name: 'GO', nome: 'Goiás' },
  { value: 'MA', name: 'MA', nome: 'Maranhão' },
  { value: 'MT', name: 'MT', nome: 'Mato Grosso' },
  { value: 'MS', name: 'MS', nome: 'Mato Grosso do Sul' },
  { value: 'MG', name: 'MG', nome: 'Minas Gerais' },
  { value: 'PA', name: 'PA', nome: 'Pará' },
  { value: 'PB', name: 'PB', nome: 'Paraíba' },
  { value: 'PR', name: 'PR', nome: 'Paraná' },
  { value: 'PE', name: 'PE', nome: 'Pernambuco' },
  { value: 'PI', name: 'PI', nome: 'Piauí' },
  { value: 'RJ', name: 'RJ', nome: 'Rio de Janeiro' },
  { value: 'RN', name: 'RN', nome: 'Rio Grande do Norte' },
  { value: 'RS', name: 'RS', nome: 'Rio Grande do Sul' },
  { value: 'RO', name: 'RO', nome: 'Rondônia' },
  { value: 'RR', name: 'RR', nome: 'Roraima' },
  { value: 'SC', name: 'SC', nome: 'Santa Catarina' },
  { value: 'SP', name: 'SP', nome: 'São Paulo' },
  { value: 'SE', name: 'SE', nome: 'Sergipe' },
  { value: 'TO', name: 'TO', nome: 'Tocantins' },
];

const optionsDatas = [
  'Personalizado',
  'Hoje',
  'Ontem',
  'Últimos 7 dias',
  'Último mês',
  'Últimos 3 meses',
  'Últimos 6 meses',
  'Último ano',
  'Esse mês',
];

const sortAZ = (listagem, desc, param) => {
  const sortaz = [...listagem];
  if (desc) {
    return sortaz.sort((a, z) =>
      a[param].toLowerCase() > z[param].toLowerCase() ? 1 : -1
    );
  } else {
    return sortaz.sort((a, z) =>
      a[param].toLowerCase() > z[param].toLowerCase() ? -1 : 1
    );
  }
};

const sortInt = (listagem, desc, param) => {
  const sortint = [...listagem];
  return orderBy(sortint, [v => v[param]], [desc ? 'desc' : 'asc']);
};

const sortFunction = (listagem, desc, param, func) => {
  const sortint = [...listagem];
  return orderBy(sortint, [v => func(v[param])], [desc ? 'desc' : 'asc']);
};

const GetDatasAjustadas = periodo => {
  var dataInicial = new Date();
  var dataFinal = new Date();

  switch (periodo) {
    case 'Personalizado':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setMonth(dataInicial.getMonth() - 3);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Hoje':
      dataInicial = new Date();
      dataFinal = new Date();
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Ontem':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setDate(dataInicial.getDate() - 1);
      dataFinal.setDate(dataFinal.getDate() - 1);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Últimos 7 dias':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setDate(dataInicial.getDate() - 7);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Último mês':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setMonth(dataInicial.getMonth() - 1);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Últimos 3 meses':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setMonth(dataInicial.getMonth() - 3);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Últimos 6 meses':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setMonth(dataInicial.getMonth() - 6);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Último ano':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setFullYear(dataInicial.getFullYear() - 1);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    case 'Esse mês':
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setDate(1);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
    default:
      dataInicial = new Date();
      dataFinal = new Date();
      dataInicial.setMonth(dataInicial.getMonth() - 3);
      return {
        dataInicial: dataInicial.toISOString().slice(0, 10),
        dataFinal: dataFinal.toISOString().slice(0, 10),
      };
  }
};

function removerNullJSON(obj) {
  let forbidden = ['ICMS', 'PIS', 'COFINS', 'IPI', 'CentrosCustos'];
  for (var k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) removerNullJSON(obj[k]);
    else if (obj[k] == null && !forbidden.includes(k)) {
      obj[k] = '';
    }
  }
}

function reload() {
  setTimeout(function () {
    window.location.reload();
  }, 1000);
}

function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('database');
  window.location.reload();
}

function addIDtoURL(oldId, id) {
  if (oldId === undefined || oldId === null || oldId === '') {
    setTimeout(() => {
      window.location.href = window.location.href + '/' + id;
      //window.location.reload(); 
    }, 500);
  }
}

function isNotNullOrEmpty(value) {
  return value !== null && value !== undefined && value !== '';
}

function ajustaDatas(periodo, setFieldValue) {
  var dataFiltro = GetDatasAjustadas(periodo);
  setFieldValue('DataInicial', dataFiltro.dataInicial);
  setFieldValue('DataFinal', dataFiltro.dataFinal.toLocaleString());
}

export {
  GetDatasAjustadas,
  addIDtoURL,
  estados,
  logout,
  optionsDatas,
  reload,
  removerNullJSON,
  sortAZ,
  sortFunction,
  sortInt,
  isNotNullOrEmpty,
  ajustaDatas,
  optionsPaises,
  optionsUFs,
  optionsUFsExportacao,
};
