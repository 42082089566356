import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import '../../App.css';
import InputData from '../../components/Inputs/InputData/InputData';
import InputSelect from '../../components/Inputs/InputSelect/InputSelect';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { getFiltroPadraoRelatorioLancamentosVencidos } from '../../utils/filtros';
import { gridColumn } from '../../utils/gridColumn';
import { ajustaDatas, optionsDatas } from '../../utils/utils';
import { baixarArquivoBase64 } from '../../utils/download';
import InputFiltro from '../../components/Inputs/InputFiltro/InputFiltro';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import Filtros from '../../components/Filtros/Filtros';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import TotalizadorRelatorio from '../../components/TotalizadorRelatorio/TotalizadorRelatorio';
import Tabela from '../../components/Tabela/Tabela';

function RelLancamentosVencidos() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [search] = useSearchParams();
  const [searchBar, setSearchBar] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  const [relLancamentosVencidos, setRelLancamentosVencidos] = useState([]);
  const [totalReceitas, setTotalReceitas] = useState(0);
  const [totalDespesas, setTotalDespesas] = useState(0);

  async function GetRelLancamentosVencidos(filtros) {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(filtros, null, 2),
    };
    let res = await api.post('/LancamentosVencidosReport/GetList', payload);
    let data = res.data;
    if (res.status === 200) {
      setRelLancamentosVencidos(data.Lancamentos);
      if (data.Lancamentos && Object.keys(data.Lancamentos).length > 0) {
        let receitas = 0;
        let despesas = 0;
        data.Lancamentos.map(rel =>
          rel.Tipo === 'Receita'
            ? (receitas += rel.ValorTotal)
            : (despesas += rel.ValorTotal)
        );
        setTotalReceitas(receitas);
        setTotalDespesas(despesas);
      } else {
        setTotalReceitas(0);
        setTotalDespesas(0);
      }
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    GetRelLancamentosVencidos(getFiltroPadraoRelatorioLancamentosVencidos());
  }, []);

  useEffect(() => {
    if (search.get('filter')) {
      setShowFilters(search.get('filter') === 'true');
      GetRelLancamentosVencidos(getFiltroPadraoRelatorioLancamentosVencidos());
    }
  }, [search]);

  function handleEdit(item) {
    navigate(`/Lancamentos/Editar/${item.Id}`);
  }

  const getPDF = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relLancamentosVencidos, null, 2),
    };
    baixarArquivoBase64('/LancamentosVencidosReport/GetPDF', payload);
  };

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(relLancamentosVencidos, null, 2),
    };
    baixarArquivoBase64('/LancamentosVencidosReport/GetExcel', payload);
  };

  return (
    <Pagina loading={loading}>
      <FormikPadrao
        initialValues={getFiltroPadraoRelatorioLancamentosVencidos()}
        onSubmit={async values => {
          await GetRelLancamentosVencidos(values);
        }}
      >
        <BarraBotoes
          maxWindowSize={1030}
          botoes={[
            {
              children: 'Exportar Planilha',
              onClick: getExcel,
              variant: 'verde',
            },
            {
              children: 'Exportar PDF',
              onClick: getPDF,
              variant: 'rosa',
            },
          ]}
          search={{
            placeholder: 'Procurar por lançamentos',
            onChange: e => setSearchBar(e.target.value),
          }}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <FilterMenu showFilters={showFilters} setShowFilters={setShowFilters} />
        <TotalizadorRelatorio
          items={[
            {
              Titulo: 'TOTAL DE RECEITAS',
              Valor: totalReceitas,
              type: 'money',
            },
            {
              Titulo: 'TOTAL DE DESPESAS',
              Valor: totalDespesas,
              type: 'money',
            },
          ]}
        />
        <Tabela
          titulo={'RELATÓRIO DE LANÇAMENTOS VENCIDOS'}
          setFunction={setRelLancamentosVencidos}
          data={relLancamentosVencidos}
          columns={[
            {
              placeholder: 'CÓDIGO',
              name: 'Codigo',
              type: 'text',
            },
            {
              placeholder: 'EMPRESA',
              name: 'Empresa',
              type: 'text',
            },
            {
              placeholder: 'TIPO',
              name: 'Tipo',
              type: 'text',
            },
            {
              placeholder: 'CLIENTE',
              name: 'Cliente',
              type: 'text',
            },
            {
              placeholder: 'BANCO',
              name: 'Banco',
              type: 'text',
            },
            {
              placeholder: 'COMPETÊNCIA',
              name: 'DataCompetencia',
              type: 'date',
            },
            {
              placeholder: 'VENCIMENTO',
              name: 'DataVencimento',
              type: 'date',
            },
            {
              placeholder: 'VALOR DO LANÇAMENTO',
              name: 'ValorTotal',
              type: 'money',
            },
            {
              placeholder: 'VALOR RESTANTE',
              name: 'ValorRestante',
              type: 'money',
            },
          ]}
          searchBar={searchBar}
          handleEdit={handleEdit}
        />
      </FormikPadrao>
    </Pagina>
  );
}

function FilterMenu({ showFilters, setShowFilters }) {
  const { values, setFieldValue } = useFormikContext();

  const optionsData = [
    { value: 'vencimento', name: 'Filtrar por Data de Vencimento' },
    { value: 'competencia', name: 'Filtrar Por Data de Competência' },
  ];

  const optionsLancamentos = [
    { value: '', name: 'Todos' },
    { value: 'Receita', name: 'Receitas' },
    { value: 'Despesa', name: 'Despesas' },
  ];

  useEffect(() => {
    ajustaDatas(values.PeriodoLancamento, setFieldValue);
  }, [values.PeriodoLancamento, setFieldValue]);

  return (
    <Filtros showFilters={showFilters} setShowFilters={setShowFilters}>
      <InputFiltro
        titulo="CLIENTE/FORNECEDOR"
        id="Cliente"
        name="Cliente"
        component={InputAutoComplete}
        autocomplete={true}
        path={'ClienteFornecedor'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="EMPRESA"
        id="Empresa"
        name="Empresa"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Empresa'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="BANCO"
        id="Banco"
        name="Banco"
        component={InputAutoComplete}
        autocomplete={true}
        path={'Bancos'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="TIPO DO LANÇAMENTO"
        id="TipoLancamento"
        name="TipoLancamento"
        component={InputSelect}
        options={optionsLancamentos}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PLANO DE CONTAS"
        id="PlanoConta"
        name="PlanoConta"
        component={InputAutoComplete}
        autocomplete={true}
        path={'PlanosContas'}
        database={localStorage.getItem('database')}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="FILTRAR POR DATA"
        id="TipoData"
        name="TipoData"
        component={InputSelect}
        options={optionsData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="PERÍODO"
        id="PeriodoLancamento"
        name="PeriodoLancamento"
        component={InputSelect}
        options={optionsDatas}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Inicial"
        id="DataInicial"
        name="DataInicial"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
      <InputFiltro
        titulo="Data Final"
        id="DataFinal"
        name="DataFinal"
        component={InputData}
        gridColumn={gridColumn.gc4}
      />
    </Filtros>
  );
}

export default RelLancamentosVencidos;
