import { Box } from '@chakra-ui/react';
import React from 'react';
import './FormText.css';

function FormText({ texto, gridColumn }) {
  return (
    <Box p={2} pt={9} gridColumn={gridColumn}>
      <p className="formText">{texto}</p>
    </Box>
  );
}

export default FormText;
