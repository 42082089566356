import { useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../App.css';
import Alerta from '../../components/Alerta/Alerta';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import Card from '../../components/Card/Card';
import InputAutoComplete from '../../components/Inputs/InputAutoComplete/InputAutoComplete';
import InputData from '../../components/Inputs/InputData/InputData';
import InputFloat from '../../components/Inputs/InputFloat/InputFloat';
import InputFormik from '../../components/Inputs/InputFormik/InputFormik';
import InputNumInt from '../../components/Inputs/InputNumInt/InputNumInt';
import InputTextArea from '../../components/Inputs/InputTextArea/InputTextArea';
import Pagina from '../../components/Pagina/Pagina';
import api from '../../services/api';
import { gridColumn } from '../../utils/gridColumn';
import { addIDtoURL } from '../../utils/utils';
import './Contratos.css';
import FormikPadrao from '../../components/FormikPadrao/FormikPadrao';
import InputToggle from '../../components/Inputs/InputToggle/InputToggle';
import PesquisaProdutos from '../../components/PesquisaProdutos/PesquisaProdutos';
import TabelaInterna from '../../components/Tabela/TabelaInterna';

function EditarContratos() {
  const navigate = useNavigate();
  const [contratos, setContratos] = useState({
    Cliente: '',
    Empresa: '',
    Produto: '',
    PlanoContas: '',
    Valor: 0,
    Desconto: 0,
    QuantidadeMeses: 0,
    DataVencimento: 0,
    DataInicio: '',
    valorTotal: '',
    Observacoes: '',
  });
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [alertStatus, setAlertStatus] = useState('success');
  const [alertTitle, setAlertTitle] = useState('');

  let { id } = useParams();

  const GetContratos = useCallback(async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: id,
    };
    let res = await api.post('/EditContratos/Get', payload);
    if (res.status === 200) {
      let data = res.data;
      setContratos(data.Contrato);
      setLoading(false);
    } else {
      alert('Erro ao buscar dados');
    }
  }, [id]);

  useEffect(() => {
    GetContratos();
  }, [GetContratos]);

  let save = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditContratos/Save', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      console.log(data);
      addIDtoURL(id, data.ItemID);
      await GetContratos();
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  let cancelar = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditContratos/Cancelar', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  let gerarLancamentos = async value => {
    setLoading(true);
    let payload = {
      data: value,
      database: localStorage.getItem('database'),
    };
    let res = await api.post('/EditContratos/GerarLancamento', payload);
    let data = res.data;
    setLoading(false);
    if (data.Success) {
      setAlertStatus('success');
      setAlertTitle(data.MSG);
      onOpen();
      addIDtoURL(id, data.ItemID);
    } else {
      setAlertStatus('error');
      setAlertTitle(data.MSG);
      onOpen();
    }
  };

  return (
    <>
      <Alerta
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertStatus={alertStatus}
        title={alertTitle}
      />
      <Pagina loading={loading}>
        <FormikPadrao
          initialValues={contratos}
          validationSchema={yup.object({
            // Nome: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
            // Empresa: yup.string('O campo é obrigatório').required('O campo é obrigatório').nullable(),
          })}
          onSubmit={async values => {
            setContratos(values);
            await save(JSON.stringify(values, null, 2));
          }}
        >
          <Interno
            navigate={navigate}
            cancelar={cancelar}
            gerarLancamentos={gerarLancamentos}
          />
        </FormikPadrao>
      </Pagina>
    </>
  );
}

function Interno({ cancelar, gerarLancamentos }) {
  const { values, setFieldValue } = useFormikContext();
  let titulo = `${values.Id !== '' ? 'Editar' : 'Novo'} Contrato`;

  async function handleAddProduto(nomeProduto) {
    let payload = {
      database: localStorage.getItem('database'),
      data: nomeProduto,
    };
    let res = await api.post('/PDV/GetProdutoTodosTipos', payload);
    if (res.status === 200) {
      let data = res.data;
      addProdutosCarrinho(data.Produtos);
    } else {
      alert('Erro ao buscar dados');
    }
  }

  function addProdutosCarrinho(produto) {
    var achou = false;
    var produtos = values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Nome === produto.Nome) {
        achou = true;
        produtos[i].Quantidade += 1;
      }
    }

    if (!achou) {
      var pnovo = {};
      pnovo.Nome = produto.Nome;
      pnovo.Quantidade = 1;
      pnovo.Detalhes = '';
      pnovo.ValorUnitario = produto.PrecoVenda;
      pnovo.ValorTotal = produto.PrecoVenda * 1;
      pnovo.DescontoUnitario = 0.0;
      produtos.push(pnovo);
    }
    setFieldValue('Produtos', produtos);
  }

  function removeProdutoCarrinho(produto) {
    var produtos = values.Produtos;
    for (var i = 0; i < produtos.length; i++) {
      if (produtos[i].Nome === produto.Nome) {
        produtos.splice(i, 1);
        break;
      }
    }
    setFieldValue('Produtos', produtos);
  }

  useEffect(() => {
    let cont = 0;
    if (!values.Valor) {
      if (values.Valor !== 0) {
        cont++;
      }
      setFieldValue('Valor', 0);
    }
    if (!values.Desconto) {
      if (values.Desconto !== 0) {
        cont++;
      }
      setFieldValue('Desconto', 0);
    }
    if (!values.QuantidadeMeses) {
      if (values.QuantidadeMeses !== 0) {
        cont++;
      }
      setFieldValue('QuantidadeMeses', 0);
    }
    if (cont) return;
    let valor = values.Valor;
    let desconto = values.Desconto;
    let meses = values.QuantidadeMeses;

    if (typeof values.Valor === 'string') {
      valor = parseFloat(values.Valor.replace(',', '.'));
    }

    if (typeof values.Desconto === 'string') {
      desconto = parseFloat(values.Desconto.replace(',', '.'));
    }

    if (typeof values.QuantidadeMeses === 'string') {
      meses = parseFloat(values.QuantidadeMeses.replace(',', '.'));
    }
    setFieldValue('ValorTotal', (valor - desconto) * meses);
  }, [values.QuantidadeMeses, values.Valor, values.Desconto, setFieldValue]);

  return (
    <>
      <BarraBotoes
        botoes={[
          {
            variant: 'turquesa',
            type: 'submit',
            children: 'Salvar',
            visible: !(values.Faturado || values.Cancelado),
          },
          {
            variant: 'amarelo',
            type: 'button',
            children: 'Cancelar Contrato',
            visible: !(values.Faturado || values.Cancelado || !values.Id),
            onClick: () => cancelar(JSON.stringify(values, null, 2)),
          },
          {
            variant: 'verde',
            type: 'button',
            children: 'Gerar Lancamentos',
            visible: !(values.Faturado || values.Cancelado || !values.Id),
            onClick: () => gerarLancamentos(JSON.stringify(values, null, 2)),
          },
        ]}
        caminho={[
          { nome: 'Contratos', path: '/Contratos/Listar' },
          { nome: titulo },
        ]}
      />
      <Card titulo={values.Id == null ? 'Editar contrato' : 'Novo contrato'}>
        <InputFormik
          titulo="Cliente"
          id="Cliente"
          name="Cliente"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Cliente"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="Empresa"
          id="Empresa"
          name="Empresa"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="Empresa"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />

        <InputFormik
          titulo="Plano de Contas"
          id="PlanoContas"
          name="PlanoContas"
          component={InputAutoComplete}
          gridColumn={gridColumn.gc4}
          path="PlanosContas"
          autocomplete={true}
          database={localStorage.getItem('database')}
        />
        {values.Faturado && (
          <InputFormik
            titulo="Faturado?"
            id="Faturado"
            name="Faturado"
            component={InputToggle}
            gridColumn={gridColumn.gc4}
            disabled
          />
        )}
        <InputFormik
          titulo="Valor da Parcela"
          id="Valor"
          name="Valor"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Desconto"
          id="Desconto"
          name="Desconto"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Quantidade de Meses"
          id="QuantidadeMeses"
          name="QuantidadeMeses"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Dia do Vencimento"
          id="DataVencimento"
          name="DataVencimento"
          component={InputNumInt}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Início"
          id="DataInicio"
          name="DataInicio"
          component={InputData}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Valor Total"
          id="ValorTotal"
          name="ValorTotal"
          component={InputFloat}
          gridColumn={gridColumn.gc4}
        />

        <InputFormik
          titulo="Observações"
          id="Observacoes"
          name="Observacoes"
          component={InputTextArea}
          gridColumn={gridColumn.gc12}
        />
      </Card>
      <Card titulo={'Produtos'}>
        <PesquisaProdutos funcao={handleAddProduto} />
        <TabelaInterna
          nomeLista="Produtos"
          columns={[
            {
              placeholder: 'Item',
              name: 'Nome',
              type: 'text',
            },
          ]}
          showDelete={true}
          handleDelete={removeProdutoCarrinho}
        />
      </Card>
    </>
  );
}

export default EditarContratos;
