import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './OrdensCompra.css';

function ListarOrdensCompra() {
  const navigate = useNavigate();
  const [ordensCompra, setOrdensCompra] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetOrdensCompra(filtros) {
      let payload = {
        database: localStorage.getItem('database'),
        data: JSON.stringify(filtros, null, 2),
      };
      let res = await api.post('/ListOrdensCompra/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Ordens.forEach(b => {
          b.Selecionado = false;
        });
        setOrdensCompra(data.Ordens);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetOrdensCompra();
  }, []);

  function handleEdit(item) {
    navigate(`/OrdensCompra/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/OrdensCompra/Editar');
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por ordens de compra',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE ORDENS DE COMPRA'}
            setFunction={setOrdensCompra}
            data={ordensCompra}
            columns={[
              {
                placeholder: 'CÓDIGO',
                name: 'Codigo',
                type: 'text',
              },
              {
                placeholder: 'EMPRESA',
                name: 'Empresa',
                type: 'text',
              },
              {
                placeholder: 'FORNECEDOR',
                name: 'Fornecedor',
                type: 'text',
              },
              {
                placeholder: 'TRANSPORTADORA',
                name: 'Transportadora',
                type: 'text',
              },
              {
                placeholder: 'DATA',
                name: 'Data',
                type: 'date',
              },
              {
                placeholder: 'PREVISÃO DE ENTREGA',
                name: 'PrevisaoEntrega',
                type: 'date',
              },
              {
                placeholder: 'STATUS',
                name: 'Status',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarOrdensCompra;
