import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import '../../App.css';

function CardNoHeader({ children, header, footer, ...props }) {
  return (
    <Flex px={4} className={`card`} overflowY="auto" direction={'column'} {...props}>
      <Box py={4}>
        {header}
      </Box>
      <Box py={4} pt={0} overflowY="auto" flexGrow={1}>
        <Flex
          // mt={4}
          flexGrow={1}
          flexDirection="column"
          id="content"
          justifyContent="center"
          width="100%"
          height="fit-content"
          minWidth="fit-content"
          minHeight="fit-content"
          flexShrink={2}
        >
          {children}
        </Flex>
      </Box>
      <Box py={4}>
        {footer}
      </Box>
    </Flex>
  );
}

export default CardNoHeader;
