import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Usuarios.css';

function ListarUsuarios() {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetUsuarios() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListUsuarios/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Usuarios.forEach(b => {
          b.Selecionado = false;
        });
        setUsuarios(data.Usuarios);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetUsuarios();
  }, []);

  function handleEdit(item) {
    navigate(`/Usuarios/Editar/${item.Id}`);
  }

  function handleClick() {
    navigate('/Usuarios/Editar');
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por usuários',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE USUÁRIOS'}
            setFunction={setUsuarios}
            data={usuarios}
            columns={[
              {
                placeholder: 'NOME',
                name: 'Username',
                type: 'text',
              },
              {
                placeholder: 'E-MAIL',
                name: 'Email',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            handleEdit={handleEdit}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarUsuarios;
