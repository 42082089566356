import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Importacao from '../../components/Importacao/Importacao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './Produtos.css';

function ListarProdutos() {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [importacao, setImportacao] = useState(false);
  const navigate = useNavigate();
  const [searchBar, setSearchBar] = useState('');

  function handleClick() {
    navigate('/Produtos/Editar');
  }

  var date = new Date();
  date.setDate(date.getDate() - 100);

  useEffect(() => {
    async function GetProdutos(filtros) {
      let payload = {
        database: localStorage.getItem('database'),
        data: JSON.stringify(filtros, null, 2),
      };
      let res = await api.post('/ListProdutos/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Produtos.forEach(b => {
          b.Selecionado = false;
        });
        setProdutos(data.Produtos);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetProdutos();
  }, []);

  function handleEdit(item) {
    navigate(`/Produtos/Editar/${item.Id}`);
  }

  const getExcel = async () => {
    let payload = {
      database: localStorage.getItem('database'),
      data: JSON.stringify(produtos, null, 2),
    };
    let res = await api.post('/ListProdutos/GetExcel', payload);
    let data = res.data;

    var pdf = data.ItemID;

    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'Produtos.xlsx';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <>
      {importacao ? (
        <>
          <Importacao
            tipo={'Produtos'}
            funcaoVoltar={() => setImportacao(false)}
            funcaoNovo={() => handleClick()}
          />
        </>
      ) : (
        <Pagina loading={loading}>
          <FormPadrao>
            <BarraBotoes
              botoes={[
                {
                  children: 'Importar em Lote',
                  onClick: () => setImportacao(true),
                  variant: 'azul',
                },
                {
                  children: 'Exportar Excel',
                  onClick: getExcel,
                  variant: 'verde',
                },
                {
                  children: 'Novo',
                  onClick: handleClick,
                  variant: 'turquesa',
                },
              ]}
              search={{
                placeholder: 'Procurar por produtos',
                onChange: e => setSearchBar(e.target.value),
              }}
            />
            <Tabela
              titulo={'LISTAGEM DE PRODUTOS'}
              setFunction={setProdutos}
              data={produtos}
              columns={[
                {
                  placeholder: 'Código',
                  name: 'Codigo',
                  type: 'text',
                },
                {
                  placeholder: 'Nome',
                  name: 'Nome',
                  type: 'text',
                },
                {
                  placeholder: 'Gênero',
                  name: 'Genero',
                  type: 'text',
                },
                {
                  placeholder: 'Marca',
                  name: 'Marca',
                  type: 'text',
                },
                {
                  placeholder: 'Preço de Venda',
                  name: 'PrecoVenda',
                  type: 'money',
                },
                {
                  placeholder: 'Saldo em Estoque',
                  name: 'SaldoEstoque',
                  type: 'number',
                },
              ]}
              searchBar={searchBar}
              handleEdit={handleEdit}
              useCheck={false}
            />
          </FormPadrao>
        </Pagina>
      )}
    </>
  );
}

export default ListarProdutos;
