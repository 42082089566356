import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import BarraBotoes from '../../components/BarraBotoes/BarraBotoes';
import FormPadrao from '../../components/FormPadrao/FormPadrao';
import Pagina from '../../components/Pagina/Pagina';
import Tabela from '../../components/Tabela/Tabela';
import api from '../../services/api';
import './MovimentacoesEstoque.css';

function ListarMovimentacoesEstoque() {
  const navigate = useNavigate();

  const [movimentacoesEstoque, setMovimentacoesEstoque] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBar, setSearchBar] = useState('');

  useEffect(() => {
    async function GetMovimentacoesEstoque() {
      let payload = {
        database: localStorage.getItem('database'),
        data: '{}',
      };
      let res = await api.post('/ListEstoques/Get', payload);
      if (res.status === 200) {
        let data = res.data;
        data.Estoques.forEach(b => {
          b.Selecionado = false;
        });
        setMovimentacoesEstoque(data.Estoques);
        setLoading(false);
      } else {
        alert('Erro ao buscar dados');
      }
    }
    GetMovimentacoesEstoque();
  }, []);

  function handleClick() {
    navigate('/MovimentacoesEstoque/Editar');
  }

  return (
    <>
      <Pagina loading={loading}>
        <FormPadrao>
          <BarraBotoes
            botoes={[
              {
                children: 'Novo',
                onClick: handleClick,
                variant: 'turquesa',
              },
            ]}
            search={{
              placeholder: 'Procurar por movimentações de estoque',
              onChange: e => setSearchBar(e.target.value),
            }}
          />
          <Tabela
            titulo={'LISTAGEM DE MOVIMENTAÇÕES DE ESTOQUE'}
            setFunction={setMovimentacoesEstoque}
            data={movimentacoesEstoque}
            columns={[
              {
                placeholder: 'TIPO',
                name: 'Tipo',
                type: 'text',
              },
              {
                placeholder: 'QUANTIDADE',
                name: 'Quantidade',
                type: 'number',
              },
              {
                placeholder: 'PRODUTO',
                name: 'Produto',
                type: 'text',
              },
              {
                placeholder: 'LOTE',
                name: 'Lote',
                type: 'text',
              },
              {
                placeholder: 'VALIDADE',
                name: 'Validade',
                type: 'date',
              },
              {
                placeholder: 'DEPÓSITO',
                name: 'DepositoDestino',
                type: 'text',
              },              
              {
                placeholder: 'VALOR TOTAL',
                name: 'ValorTotal',
                type: 'money',
              },              
              {
                placeholder: 'DATA',
                name: 'Data',
                type: 'date',
              },
              {
                placeholder: 'HORA',
                name: 'Hora',
                type: 'text',
              },
              {
                placeholder: 'ORIGEM',
                name: 'DepositoOrigem',
                type: 'text',
              },
            ]}
            searchBar={searchBar}
            showDelete={false}
            useCheck={false}
          />
        </FormPadrao>
      </Pagina>
    </>
  );
}

export default ListarMovimentacoesEstoque;
