import { Box } from '@chakra-ui/react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import './InputFiltro.css';

function InputFiltro({
  titulo,
  gridColumn,
  component,
  autocomplete = false,
  ...rest
}) {
  const formik = useFormikContext();

  if (autocomplete) {
    return (
      <Box p={2} gridColumn={gridColumn} className="filtroContainer">
        <p className="pFiltros">{titulo.toUpperCase()}</p>
        <Field as={component} onSourceSelect={formik.setFieldValue} {...rest} />
        <ErrorMessage name={rest.name} />
      </Box>
    );
  }
  return (
    <Box p={2} gridColumn={gridColumn} className="filtroContainer">
      <p className="pFiltros">{titulo.toUpperCase()}</p>
      <Field as={component} {...rest} />
      <ErrorMessage name={rest.name} />
    </Box>
  );
}

export default InputFiltro;
