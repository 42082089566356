import React, { useEffect, useState } from 'react';
import { BsList } from 'react-icons/bs';
import { IoMdNotificationsOutline, IoMdNotifications } from 'react-icons/io';
import { IoPersonCircle } from 'react-icons/io5';
import Logo from '../../images/logo-erp-colorido.png';
import './Header.css';

function Header({ handleMenuChange }) {
  const [user, setUser] = useState('emaildefault@email.com');
  const emailUser = localStorage.getItem('user');
  const [nome, setNome] = useState('Leonardo');
  const [cargo, setCargo] = useState('Colaborador');
  const [notificacoes, setNotificacoes] = useState(0);

  useEffect(() => {
    if (emailUser) {
      const { email } = JSON.parse(emailUser);
      setUser(email);
    }
  }, [emailUser]);

  return (
    <div className="header">
      <div className="saudacao">
        <button
          type="button"
          onClick={handleMenuChange}
        >
          <BsList className="iconemenuHeader" />
        </button>
        <h3 className="bomdia">BOM DIA,</h3>
        <h3 className="user">{user}! </h3>
      </div>
      <div className="icones">
        <button
          type="button"
          className="buttonnot"
        // onClick={}
        >
          <div className="notificacao">
            {IconeNotificacoes(notificacoes)}
          </div>
        </button>
        <div className='separator' />
        <button
          type="button"
          className="buttonnot"
        // onClick={}
        >
          {
            //Funcionario(nome, cargo, Logo)
            Funcionario(nome, cargo)
          }
        </button>
      </div>
    </div>
  );
}

function IconeNotificacoes(notificacoes) {
  if (notificacoes > 0) {
    return (
      <>
        <span className="numeroNotificacao">
          {notificacoes > 9 ? "+" : notificacoes}
        </span>
        <IoMdNotifications
          className="notificacao"
          size="24px"
        />
      </>
    );
  }
  return (
    <IoMdNotificationsOutline
      className="notificacaoIcone"
      size="24px"
    />
  );
}

function Funcionario(nome, cargo, logo = null) {
  return (
    <div className='funcionarioHeader'>
      {logo ?
        <img className='img' src={logo} alt="Logo" />
        :
        <IoPersonCircle
          size="44px"
          className='img'
        />
      }
      <div>
        <p>{nome}</p>
        <span className='cargo'>{cargo}</span>
      </div>
    </div>
  );
}

export default Header;
