const optionsFinalidade = [
  { value: '1', name: 'Normal' },
  { value: '2', name: 'NF-e Complementar' },
  { value: '3', name: 'Ajuste' },
  { value: '4', name: 'Devolução/Retorno' },
];

const optionsTipoNFe = [
  { value: '0', name: 'Entrada' },
  { value: '1', name: 'Saída', selected: true },
];

const optionsCSOSN = [
  {
    value: '101',
    name: '101 - Tributada pelo Simples Nacional com permissão de crédito de ICMS',
  },
  {
    value: '102',
    name: '102 - Tributada pelo Simples Nacional sem permissão de crédito',
  },
  {
    value: '103',
    name: '103 - Isenção de ICMS no Simples Nacional na faixa de receita bruta',
  },
  {
    value: '201',
    name: '201 - Tributada pelo Simples Nacional com permissão de crédito e cobrança do ICMS por ST',
  },
  {
    value: '202',
    name: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por ST',
  },
  {
    value: '203',
    name: '203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e cobrança de ICMS por ST',
  },
  { value: '300', name: '300 - Imune de ICMS' },
  { value: '400', name: '400 - Não tributada pelo Simples Nacional' },
  {
    value: '500',
    name: '500 - ICMS cobrado anteriormente por ST ou por antecipação',
  },
  { value: '900', name: '900 - Outros' },
];

const optionsIndicadorIE = [
  { value: '1', name: 'Contribuinte ICMS' },
  { value: '2', name: 'Isento' },
  { value: '9', name: 'Não contribuinte' },
];

const optionsFrete = [
  { value: '9', name: 'Não haverá frete' },
  { value: '0', name: 'Minha Empresa (Por conta do Emitente)' },
  { value: '1', name: 'Meu Cliente (Por conta do Destinatário)' },
  { value: '2', name: 'Por conta de Terceiros' },
  { value: '3', name: 'Transporte Próprio (Por conta do Emitente)' },
  { value: '4', name: 'Transporte Próprio (Por conta do Destinatário)' },
];

const optionsFormaPagamento = [
  { value: 'Dinheiro', name: 'Dinheiro' },
  { value: 'Boleto', name: 'Boleto' },
  { value: 'Cheque', name: 'Cheque' },
  { value: 'CartaoDeCredito', name: 'Cartao de Crédito' },
  { value: 'CartaoDeDebito', name: 'Cartao de Débito' },
  { value: 'CreditoLoja', name: 'Crédito Loja' },
  { value: 'ValeAlimentacao', name: 'Vale Alimentação' },
  { value: 'ValeRefeicao', name: 'Vale Refeição' },
  { value: 'ValePresente', name: 'Vale Presente' },
  { value: 'ValeCombustivel', name: 'Vale Combustível' },
  { value: 'DuplicataMercantil', name: 'Duplicata Mercantil' },
  { value: 'DepositoBancario', name: 'Deposito Bancário' },
  { value: 'PIX', name: 'PIX' },
  { value: 'Programadefidelidade', name: 'Programa de Fidelidade' },
  { value: 'SemPagamento', name: 'Sem Pagamento' },
  { value: 'Transferenciabancaria', name: 'Transferência bancária' },
];

const optionsTipoPagamento = [
  { value: 'A vista', name: 'A vista' },
  { value: 'A prazo', name: 'A prazo' },
  { value: 'Outro', name: 'Outro' },
];

const optionsSituacoesTributariasIPI = [
  { value: '0', name: '00 - Entrada com Recuperação de Crédito' },
  { value: '4', name: '01 - Entrada Tributável com Alíquota Zero' },
  { value: '5', name: '02 - Entrada Isenta' },
  { value: '6', name: '03 - Entrada Não-Tributada' },
  { value: '7', name: '04 - Entrada Imune' },
  { value: '8', name: '05 - Entrada com Suspensão' },
  { value: '1', name: '49 - Outras Entradas' },
  { value: '2', name: '50 - Saída Tributada' },
  { value: '9', name: '51 - Saída Tributável com Alíquota Zero' },
  { value: '10', name: '52 - Saida Isenta' },
  { value: '11', name: '53 - Saída Não-Tributada' },
  { value: '12', name: '54 - Saída Imune' },
  { value: '13', name: '55 - Saída com Suspensão' },
  { value: '3', name: '99 - Outras Saídas' },
];

export {
  optionsFinalidade,
  optionsTipoNFe,
  optionsCSOSN,
  optionsIndicadorIE,
  optionsFrete,
  optionsFormaPagamento,
  optionsTipoPagamento,
  optionsSituacoesTributariasIPI,
};
