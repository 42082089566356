import { Box } from '@chakra-ui/react';
import React from 'react';

import styled from 'styled-components';

const Secao = styled(Box)`
  background-color: white;
  border-left: solid 5px #16d105;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  min-width: 100%;
`;

const Titulo = styled.h3`
  color: rgb(104, 102, 102);
  font-weight: bold;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-top: 3px;
`;

function SecaoCadastro({ titulo, ...rest }) {
  return (
    <Secao p={4} {...rest}>
      <Titulo>{titulo}</Titulo>
    </Secao>
  );
}

export default SecaoCadastro;
